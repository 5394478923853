import { Injectable } from '@angular/core';
import { DataService, userUrl, userOptUrl, delUserUrl, fileUploadUrl, userPaginationUrl } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  constructor(private dataService: DataService) { }

  getUserList(){
    return this.dataService.get(userUrl, null);
    // return this.dataService.get(usersUrl, null);
  }

  userOpt(body: any, opt: string){
    if(opt === 'delete')
      return this.dataService.userOpt(delUserUrl, body, opt)
    else
      return this.dataService.userOpt(userOptUrl, body, opt)
  }

  upload(body: any, files: File){
    return this.dataService.fileUpload(fileUploadUrl, body, files)
  }

  userPagination(body : any){
    return this.dataService.post(userPaginationUrl, body);
  }
}
