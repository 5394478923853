import { Component, OnInit,ViewChild,HostListener, NgModule, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Collection } from '../shared/collection.model';
import { CollectionService } from '../shared/collection.service';
import { CollectionData } from '../shared/collection.prov';
import { RelationalRefData } from 'src/app/order/shared/order.prov';
@Component({
  selector: 'app-collection-complete',
  templateUrl: './collection-complete.component.html',
  styleUrls: ['./collection-complete.component.scss'],
  providers: [  CollectionService ]  
})
export class CollectionCompleteComponent implements OnInit, OnDestroy {

  public id: string;
  store: Store;
  collRefCode : string;
  remarks : any;
  address : any;
  shop_incharge : any;
  shop_date_hopes : any;
  urg_flg : any;
  isClearRefs = true;
  phone_number : any;

   constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private collectionService: CollectionService,
    private collectionData: CollectionData,
    public http: HttpClient,
    private refData:RelationalRefData,
  ){}

  ngOnInit() {
    this.collRefCode = this.collectionData.ref_code;  
    this.id = this.route.snapshot.paramMap.get('id');
    this.store = this.collectionData.store;
    this.remarks = this.collectionData.remarks;
    this.address = this.collectionData.address;
    this.shop_incharge = this.collectionData.shop_incharge;
    this.shop_date_hopes = this.collectionData.shop_date_hopes;
    this.urg_flg = this.collectionData.urg_flg;
    this.phone_number = this.collectionData.phone_number;
 
    this.collectionData.store = '';
    this.collectionData.store_id = '';
    this.collectionData.remarks = '';
    this.collectionData.address = '';
    this.collectionData.shop_incharge = '';
    this.collectionData.shop_date_hopes = '';
    this.collectionData.urg_flg = '';
    this.collectionData.ref_code = '';
    this.collectionData.phone_number = ''
    console.log('CollectionCompleteComponent', this.refData)
  } 

  ngOnDestroy() {
    if(this.isClearRefs){
      this.refData.inq_ref = ''
      this.refData.order_ref = ''
      this.refData.coll_ref = ''
      this.refData.mainte_ref = ''
      this.refData.updID = null
    }
  }

  toMaintenance(){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/maintenance/input/'+this.id]);
  }

  toOrder(){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/order/input/'+this.id]);
  }

}
