import { ItemCategFirstService } from './../shared/item-categ-first.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';

export interface KeyValue {
  key: string;
  value: string;
}

@Component({
  selector: 'app-item-categ-first-opt',
  templateUrl: './item-categ-first-opt.component.html',
  styleUrls: ['./item-categ-first-opt.component.scss']
})
export class ItemCategFirstOptComponent implements OnInit {

  categForm: FormGroup;
  opt: string
  disabledSubmit: boolean = false;
  utype: KeyValue[] = [
    { key: "av", value: "Disabled" },
    { key: "un", value: "Active" }
  ];
  utypeControl = new FormControl('', [Validators.required]);
  isLoad = false
  showLoading = false
  showErrorText = false
  errorText = ''

  constructor(public dialogRef: MatDialogRef<ItemCategFirstOptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder, private authService: ItemCategFirstService) { }

  ngOnInit() {  
    if(this.data.option === 'edit') this.opt = '変更'
    else this.opt = '商品大カテゴリー新着追加'
    this.createForm();
    console.log(this.data.option)
  }

  createForm(): void {
    this.categForm = this.formBuilder.group({
      first_cat_name: [ '', Validators.required ],
      first_note: [ '', Validators.required ]
    });
  }

  onSubmit(){
    this.isLoad = true
    this.showLoading = true
    if (this.categForm.invalid) {
      this.showLoading = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    }
    // this.disabledSubmit = true;
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let val: any = this.categForm.getRawValue()

    let body: any;
    if(this.data.option === 'add')
      body = { 'first_cat_name': val.first_cat_name,
        'first_note': val.first_note,
        'user_id': tmp.userid }
    else if(this.data.option === 'edit')
      body = { 'first_cat_no': this.data.first_cat_no,
        'first_cat_name': val.first_cat_name,
        'first_note': val.first_note,
        'user_id': tmp.userid }

    this.authService.saveCateg(body, this.data.option)
    .subscribe(res => {
      if(res){
        this.dialogRef.close(true)
      }else{
        this.showLoading = false
        this.showErrorText = true
        this.errorText = '接続の問題が再試行してください'
      }
    })
  }

  cancelSave(){
    this.isLoad = false
    this.showLoading = false
    this.showErrorText = false
  }

}
