import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { InboundService } from '../shared/inbound.service';
import { Inquiry, Orders, Maintenance, Collection } from '../shared/inbound.model';
import { InboundViewComponent } from '../inbound-view/inbound-view.component';
import { InboundViewOrderComponent } from '../inbound-view-order/inbound-view-order.component';
import { InboundViewCollComponent } from '../inbound-view-coll/inbound-view-coll.component';
//import { StoreService } from '../../store-management/shared/store.service';
import { InboundViewMainteComponent } from '../inbound-view-mainte/inbound-view-mainte.component';
import * as status_message from '../shared/status_message.json'
import { formatDate } from '@angular/common';
import { ExportUtility } from '../../dashboard/shared/order-confirm/export-utility';
import { StoreService } from '../../store/shared/store.service';
import { Chain_list } from 'src/app/chain-management/shared/chain.model';
import { ChainService } from 'src/app/chain-management/shared/chain.service';

export interface CsvFormat {
  item_no: string
  name: string
  qty: string,
  total_amount: string
  blank: string
  wms_id: string
  price: string
  def: string
  order_no: string
}

@Component({
  selector: 'app-inbound-list',
  templateUrl: './inbound-list.component.html',
  styleUrls: ['./inbound-list.component.scss']
})



export class InboundListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('table') table1: MatSort;
  @ViewChild('table2sort') table2: MatSort;
  @ViewChild('table3sort') table3: MatSort;
  @ViewChild('table4sort') table4: MatSort;
  @ViewChild('table4sort') table5: MatSort;

  inq: Inquiry[] = [];
  ordrs: any[] = []
  mainte: any[] = []
  collection: any[] = []
  dataSource: MatTableDataSource<Inquiry>;
  dataSource2: MatTableDataSource<Orders>;
  dataSource3: MatTableDataSource<Maintenance>;
  dataSource4: MatTableDataSource<Collection>;
  displayedColumns = ['id', 'inq_ref_no', 'urg_flg', 'details', 'esc_direction', 'viewAction'];
  displayedColumns2 = ['id2', 'order_ref_no', 'urg_flg', 'note', 'esc_direction', 'csvdl','viewAction2'];
  displayedColumns3 = ['id3', 'ref_no', 'type', 'detail', 'vender',  'viewAction3'];
  displayedColumns4 = ['id4', 'coll_ref_no', 'urg_flg', 'remarks', 'shop_incharge', 'viewAction4'];

  pbVisible: boolean = false;
  status_msg = status_message.default[0]
  mainte_type: string[] = ['','修理','交換','調査','その他']
  constructor(public service: InboundService, public dialog: MatDialog,
    public store_service: StoreService, public chainService: ChainService ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.inq);
    this.getInboundInquiry();
    this.getChains()
    this.dataSource.sort = this.sort;
    // this.table1 = this.sort;

    this.dataSource2 = new MatTableDataSource(this.ordrs);
    //this.getInboundOrders()
    this.dataSource2.sort = this.table2
    // this.table2 = this.sort;

    this.dataSource3 = new MatTableDataSource(this.mainte);
    //this.getMaintenance()
    this.dataSource3.sort = this.table3
    // this.table2 = this.sort;

    this.dataSource4 = new MatTableDataSource(this.collection);
    //this.getCollection()
    this.dataSource4.sort = this.table4
    // this.table2 = this.sort;



  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  applyFilter3(filterValue: string) {
    this.dataSource3.filter = filterValue.trim().toLowerCase();
  }

  applyFilter4(filterValue: string) {
    this.dataSource4.filter = filterValue.trim().toLowerCase();
  }

  getCollectionStatus(head:Number,level:Number){
    for(let x = 0; x < this.status_msg["Collection"].length; x++){
      let col = this.status_msg["Collection"][x]
      if ( col['level'] == level && col['head'] == head ){
        return col['message']
      }
    }
  }

  getMaintenanceStatus(head:Number,level:Number,task_status:Number,comment_hd:string,cc_view:Number,hd_view:Number){
    for(let x = 0; x < this.status_msg["Maintenance"].length; x++){
      let col = this.status_msg["Maintenance"][x]
      if ( col['level'] == level && col['head'] == head && col['status'] == task_status){

        if (head == 1 && level == 2 && task_status == 1 && (comment_hd == '' || comment_hd == null)) {
          if ( col['level'] == level && col['head'] == head && col['status'] == task_status && col['hd_view'] == 1){
            return col['message']
          }
        }
        else if (head == 1 && level == 2 && task_status == 1 && comment_hd != '') {
          if (col['level'] == level && col['head'] == head && col['status'] == task_status && col['hd_view'] == 2 ){
            return col['message']
          }
        }
        else {
          return col['message']
        }

      }


    }
  }

  getInquiryStatus(head:Number,level:Number,task_status:Number){
    for(let x = 0; x < this.status_msg["Inquiry"].length; x++){
      let col = this.status_msg["Inquiry"][x]
      if ( col['level'] == level && col['head'] == head ){
        return col['message']
      }
    }
  }
  getOrderStatus(head:Number,level:Number,task_status:Number){
    for(let x = 0; x < this.status_msg["Order"].length; x++){
      let col = this.status_msg["Order"][x]
      if ( col['level'] == level && col['head'] == head ){
        return col['message']
      }
    }
  }
  getOrderStatus_Cancel(task_cccancel:Number,task_whcancel:Number){
    if (task_cccancel != 0 || task_whcancel != 0) {
      for(let x = 0; x < this.status_msg["Order"].length; x++){
        let cancel = this.status_msg["Order"][x]
        if ( cancel['cc_cancel'] == task_cccancel && cancel['wh_cancel'] == task_whcancel ){
          return cancel['message']
        }
      }
    }
  }
  getOrderProcessor(head:Number,level:Number,task_status:Number,task_cccancel:Number,task_whcancel:Number){
    for(let x = 0; x < this.status_msg["Order"].length; x++){
      let col = this.status_msg["Order"][x]
      if ( col['level'] == level && col['head'] == head ){
        if (task_cccancel == 1 || task_whcancel == 1) {
          return 'なし'
        }else{
          return col['processor']
        }

      }
    }
  }

  getInboundInquiry(){
    let body = {
      "chainId": this.chainId1,
      "statHead": this.statHead1,
      "statLevel": this.statLevel1
    }
    this.service.getList(1, body)
    .subscribe(res => {
      console.log('getInboundInquiry',res)
      this.pbVisible = true
      this.inq = res.payload.inquiries
      this.dataSource.data = this.inq
      this.dataSource.filterPredicate = (data: Inquiry, filters: string) =>{
        const matchFilter = [];
        const filterArray = filters.split(' ')
        const columns = [data.inq_ref_no]

        filterArray.forEach(filter =>{
          const customFilter = [];
          columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
          matchFilter.push(customFilter.some(Boolean));
        })
        return matchFilter.every(Boolean);
      }
    })
  }


  openInquiry(val: Inquiry){
    this.dialog.open(InboundViewComponent,
      {
        width: '980px',
        data: val
      })
  }

  openOrder(val: Orders){
    this.dialog.open(InboundViewOrderComponent,
      {
        width: '980px',
        data: val
      })
  }

  openCollection(val: Collection){

    this.dialog.open(InboundViewCollComponent,
      {
        width: '980px',
        data: val
      }
    )
  }
  openMaintenance(val: Maintenance){
    this.dialog.open(InboundViewMainteComponent,
      {
        width: '980px',
        data: val
      }
    )
  }

  getUrg(stat: number): string{
    if(stat === 0) return ''
    return '至急'
  }

  getDir(dir: string): string{
    if(dir == '0') return 'コールセンター'
    else if(dir == '2') return '博報堂'
    else if(dir == '1') return 'ラウンダー'
    else if(dir == '4') return '倉庫'
    else if(dir == '7') return 'なし'
  }

  getType(type: string){
    if(type == '0') return '修理'
    else if(type == '2') return '交換'
    else if(type == '1') return '調査'
    else if(type == '4') return 'その他'
  }

  getInboundOrders(){
    let body = {
      "chainId": this.chainId2,
      "statHead": this.statHead2,
      "statLevel": this.statLevel2,
      "cc_cancel": this.cc_cancel,
      "wh_cancel": this.wh_cancel
    }
    console.log('getInboundOrders', body)
    this.service.getList(2, body)
    .subscribe(res => {
      this.pbVisible = true
      this.ordrs = res.payload.order_data
      console.log('inbound orders', this.ordrs)
      this.dataSource2.data = this.ordrs
      this.dataSource2.filterPredicate = (data: any, filters: string) =>{
        const matchFilter = [];
        const filterArray = filters.split(' ')
        const columns = [data.order_record.order_ref_no]

        filterArray.forEach(filter =>{
          const customFilter = [];
          columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
          matchFilter.push(customFilter.some(Boolean));
        })
        return matchFilter.every(Boolean);
      }
    })
  }

  getMaintenance(){
    let body = {
      "chainId": this.chainId3,
      "statHead": this.statHead3,
      "statLevel": this.statLevel3,
      "escStat": this.escStat
    }
    this.service.getList(3, body)
    .subscribe(res => {
      console.log('getMaintenance', res)
      this.pbVisible = true
      this.mainte = res.payload.inbound_maintenance
      this.dataSource3.data = this.mainte
      this.dataSource3.filterPredicate = (data: any, filters: string) =>{
        const matchFilter = [];
        const filterArray = filters.split(' ')
        const columns = [data.ref_code]

        filterArray.forEach(filter =>{
          const customFilter = [];
          columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
          matchFilter.push(customFilter.some(Boolean));
        })
        return matchFilter.every(Boolean);
      }
    })
  }

  getCollection(){
    let body = {
      "chainId": this.chainId4,
      "statHead": this.statHead4,
      "statLevel": this.statLevel4
    }
    this.service.getList(4, body)
    .subscribe(res => {
      console.log('getCollection', res)
      this.pbVisible = true
      this.collection = res.payload.inbound_collection
      this.dataSource4.data = this.collection
      this.dataSource4.filterPredicate = (data: any, filters: string) =>{
        const matchFilter = [];
        const filterArray = filters.split(' ')
        const columns = [data.coll_ref_no]
        filterArray.forEach(filter =>{
          const customFilter = [];
          columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
          matchFilter.push(customFilter.some(Boolean));
        })
        return matchFilter.every(Boolean);
      }
    })
  }
  store:any;
  exportFile(row_data:any): void {
    console.log("CSV DL -- ",row_data)
    this.store_service.getStore(row_data.order_record.store_id).subscribe(res => {
      if (res.success === true) {
        this.store = res.payload.store;

        let myCSV: CsvFormat[] = []
        let store_data = this.store
        let general_data = row_data.order_record
        let mydata: any[] = row_data.ordered_items
        let hoped_date = ""
        let hoped_format = ""
        if (formatDate(new Date(general_data.hoped_date), 'yyyy-MM-dd', 'en-US').toString() == "1970-01-01") {
          hoped_date = "0000"
        }
        else {
          hoped_date = formatDate(new Date(general_data.hoped_date), 'MMdd', 'en-US')
        }

        let hoped_time :any
        if (general_data.hoped_time == 3){
          hoped_time = ''
        }else{
          hoped_time = general_data.hoped_time
        }

        hoped_format = hoped_date.toString() + hoped_time.toString()
        let woPrefCity = [];
        let woPref = this.store[0].address.split(this.store[0].pref.toString())
        woPrefCity = woPref[woPref.length - 1].toString().split(this.store[0].city.toString())
        let address_3 = ""
        let address_3_add = ""
        let address_3_pic = ""
        address_3_add = general_data.addressee + " 御中"
        let address_3_space = " ";
        address_3_pic = general_data.person_in_charge + " 様"
        if (general_data.addressee == "" || general_data.addressee == null) {
          address_3_add = ""; address_3_space = ""
        }
        if (general_data.person_in_charge == "" || general_data.person_in_charge == null) {
          address_3_pic = ""; address_3_space = ""
        }
        address_3 = address_3_add.toString() + address_3_space.toString() + address_3_pic.toString()

        for (let y = 0; y < mydata.length; y++) {
          let toCSV: any = {
            "注文ID": mydata[y].order_no,
            "注文日": formatDate(new Date(general_data.created_date), 'yyyy-MM-dd', 'en-US'),
            "注文時間": formatDate(new Date(general_data.created_date), 'HH:mm:ss', 'en-US'),
            "商品名": mydata[y].name,
            "商品番号": mydata[y].item_ref_no,
            "個数": mydata[y].qty,
            "単価": mydata[y].price,
            "注文者名字": '',
            "注文者名前": '',
            "メールアドレス": '',
            "注文者郵便番号1": '',
            "注文者郵便番号2": '',
            "注文者住所1:都道府県": '',
            "注文者住所2:都市区": '',
            "注文者住所3:町以降": '',
            "注文者電話番号1": '',
            "注文者電話番号2": '',
            "注文者電話番号3": '',
            "送付先名字": '',
            "送付先名前": (general_data.ship_flag == '1') ? general_data.shipping_name : store_data[0].store_name, //T //general_data.addressee + " " + general_data.person_in_charge ,
            "送付先郵便番号1": (general_data.ship_flag == '1') ? general_data.shipping_postal_code : store_data[0].post_code, //U
            "送付先郵便番号2": '',
            "注文者住所1": (general_data.ship_flag == '1') ? general_data.shipping_pref : store_data[0].pref, //W
            "注文者住所2": (general_data.ship_flag == '1') ? general_data.shipping_address : woPref[woPref.length - 1], //X
            "注文者住所3": address_3,
            "送付先電話番号1": (general_data.ship_flag == '1') ? general_data.shipping_contact_number : store_data[0].tel, //Z
            "送付先電話番号2": '',
            "送付先電話番号3": '',
            "決済方法": '銀行振込',
            "配送日指定": hoped_format,
            "配送方法": '',
            "時間帯指定": '',
            "品代合計(税込)": '',
            "送料(税込)": '',
            "代引き手数料(税込)": '',
            "合計金額(税込)": '',
            "調整額": '',
            "割引額(ポイント利用額)": '',
            "ポイント残高": '',
            "請求金額(税込)": '',
            "備考": general_data.person_in_charge,
            "別名1": general_data.dept_name,
            "荷扱い1": '',
            "メール通知": '',
            "入力機種": '',
            "通知メッセージ": '',
            "止め置き": '',
            "営業所コード": '',
            "熨斗フラグ": '',
            "ギフトフラグ": '',
            "倉庫備考": ''
          }
          myCSV.push(toCSV)
        }

        ExportUtility.downloadcsv(myCSV, "data_");


      } else {
        console.log("CAN NOT STOEgetStoreInfo OBTAINED");
      }
    });

  } // end of exportFile()

  changeTab(event: any){
    this.tabPosition = event.index
    switch(event.index){
      case 0:{
        this.getInboundInquiry()
        break
      }case 1:{
        this.getInboundOrders()
        break
      }case 2:{
        this.getMaintenance()
        break
      }case 3:{
        this.getCollection()
        break
      }
    }
  }

  chains: Chain_list[] = []
  tabPosition: number = 0
  chainId1: string = 'all'
  statHead1: string = 'all'
  statLevel1: string = 'all'
  chainId2: string = 'all'
  statHead2: string = 'all'
  statLevel2: string = 'all'
  chainId3: string = 'all'
  statHead3: string = 'all'
  statLevel3: string = 'all'
  chainId4:string = 'all'
  statHead4: string = 'all'
  statLevel4: string = 'all'
  cc_cancel: string
  wh_cancel: string
  escStat: string

  getChains(){
    this.chainService.getUserList()
    .subscribe(res => {
      this.pbVisible = true
      console.log('GET CHAINSSSS ', res)
      this.pbVisible = true
      this.chains = res.payload.chains

    })
  }

  filterInChain(event: any, val: any){
    if(event.isUserInput){
      switch(this.tabPosition){
        case 0:{
          this.chainId1 = val
          this.getInboundInquiry()
          break
        }case 1:{
          this.chainId2 = val
          this.getInboundOrders()
          break
        }case 2:{
          this.chainId3 = val
          this.getMaintenance()
          break
        }case 3:{
          this.chainId4 = val
          this.getCollection()
          break
        }
      }
    }
  }

  filterInStatus(event: any, head: any, level: any, cc_cancel: any, wh_cancel: any, escStat: any){
    if(event.isUserInput){
      switch(this.tabPosition){
        case 0:{
          this.statHead1 = head
          this.statLevel1 = level
          this.getInboundInquiry()
          break
        }case 1:{
          this.statHead2 = head
          this.statLevel2 = level
          this.cc_cancel = cc_cancel
          this.wh_cancel = wh_cancel
          this.getInboundOrders()
          break
        }case 2:{
          this.statHead3 = head
          this.statLevel3 = level
          this.escStat = escStat
          this.getMaintenance()
          break
        }case 3:{
          this.statHead4 = head
          this.statLevel4 = level
          this.getCollection()
          break
        }
      }
    }
  }

}
