import { chainsUrl, addChainUrl, delChainUrl } from './../../shared/data.service';
import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class ChainService {

  constructor(private dataService: DataService) { }

  getUserList(){
    return this.dataService.get(chainsUrl, null);
    // return this.dataService.get(coopList, null);
  }

  saveChain(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.chainOpt(delChainUrl, body, opt)
    else
      return this.dataService.chainOpt(addChainUrl, body, opt)
  }
}
