import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { StoreThirdCatUrl, } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class StoreThirdCatService {

  constructor(private dataService: DataService) { }

  getThirdCategList(){
    return this.dataService.get(StoreThirdCatUrl, null);
  }
  saveCateg(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.storeThirdCatOpt(StoreThirdCatUrl + '/delete', body, opt)
    else
      return this.dataService.storeThirdCatOpt(StoreThirdCatUrl, body, opt)
  }
}
