import { InquiryService } from './../../inquiry/shared/inquiry.service';
import { Component, OnInit,ViewChild,HostListener, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Collection } from '../shared/maintenance.model';
import { MaintenanceService } from '../shared/maintenance.service';
import { MaintenanceOptComponent } from '../maintenance-opt/maintenance-opt.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MyImage, MainFile } from '../shared/MyImage.model';
import { MaintenanceData } from '../shared/maintenance.prov';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-maintenance-input-parent',
  templateUrl: './maintenance-input.component.html',
  styleUrls: ['./maintenance-input.component.scss'],
  providers: [ StoreService, MaintenanceService, InquiryService ]
})

export class MaintenanceInputComponent implements OnInit {

  public id: string;
  store: Store;
  inquiry: Collection;
  isLoading = false;
  hasData: boolean = false;
  message = '';
  pMsg = '';
  uFlag = false;
  mfiles: MainFile[] = []
  imgs: MyImage[] = []
  mform: FormGroup;
  items = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private maintenanceService: MaintenanceService,
    private maintenanceData: MaintenanceData,
    public http: HttpClient,
    public dialog: MatDialog,
    private inquiryService: InquiryService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ){}

  ngOnInit() {
    console.log('wewew', this.maintenanceData.mainFile)
    if(this.maintenanceData.mainFile){
      this.mfiles = this.maintenanceData.mainFile
      let ftmp: File[] = []
      for(let tmp of this.mfiles) ftmp.push(tmp.mfile)
      for(let file of ftmp){
        var reader = new FileReader();
        reader.onload = (e: any) => {
          const f = file
          this.imgs.push({
            img: e.target.result,
            name: f.name,
            modif: f.lastModified,
            size: f.size
          })
        }
        reader.readAsDataURL(file);
      }
    }

    this.mform = this.formBuilder.group({
      item_selected : [this.maintenanceData.data ? this.maintenanceData.data.item_selected.item_id : undefined, Validators.required],
      type: [this.maintenanceData.data ? this.maintenanceData.data.type : 'repair', Validators.required],
      message: [this.maintenanceData.data ? this.maintenanceData.data.message : '', Validators.required],
    })

    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore();

    /*if(this.collectionData.collection){
      if(this.collectionData.collection.length>0){
        this.pMsg = this.collectionData.inqucollectionry.detail;
      }
    }*/
    this.uFlag = false;
  }
  
  getStore() {
    this.storeService.getStore(this.id).subscribe(res => {
      this.store = res.payload.store[0];
      this.getChainName();
      this.getItems();
    });
  }

  getItems() {
    this.maintenanceService.getItems().subscribe(res => {
      this.items = res.payload; 
      console.log(this.items)
    });
  }

  chainName: string
  getChainName(){
    this.inquiryService.getChainName(this.store.chain_no)
    .subscribe(res => {
      this.chainName = res.payload.chain[0].name
    })
  }

  onFlagChange(){
    if(this.uFlag === true){
      this.uFlag = true;
    } else {
      this.uFlag = false;
    }
  }

  maxCount = 1000;
  restCount = this.maxCount;
  alertStyle = {};
  
  inputText() {
    this.restCount = this.maxCount - this.message.length;

    if ( this.restCount > 30 ) {
      this.alertStyle = {color: '#000', fontWeight: 'normal'};
    } else if ( this.restCount > 0 ) {
      this.alertStyle = {color: '#f33', fontWeight: 'normal'};
    } else {
      this.alertStyle = {color: '#f00', fontWeight: 'bold'};      
    }
  }

  openUploadPhoto(){

    const dialogRef = this.dialog.open(MaintenanceOptComponent, { width: '850px',
    
      disableClose: true 
    })
    dialogRef.afterClosed().subscribe(res => {
      let tmpfile = res
      for(let file of tmpfile){
        var reader = new FileReader();
        reader.onload = (e: any) => {
          const f = file
          this.mfiles.push({
            mfile: f,
            mess: ''
          })
          this.imgs.push({
            img: e.target.result,
            name: f.name,
            modif: f.lastModified,
            size: f.size
          })
        }
        reader.readAsDataURL(file);
      }
    })
  }

  public setval(event: any, x: number){
    this.mfiles[x].mess = event.target.value
  }

  public del(x: number){
    this.mfiles.splice(x, 1)
    this.imgs.splice(x, 1)
  }

  onSubmit(store) {
 
    let formVal = this.mform.getRawValue()
    this.maintenanceData.mainFile = this.mfiles;
 
    if(this.maintenanceData.mainFile.length > 0) {
      var a = 0;
      // this.maintenanceData.mainFile.forEach(function(v,k){
      //   if(v.mess) {
      //     a += 1 ;
      //   }
      // });

      // if(this.maintenanceData.mainFile.length !== a) {
      //   this.snackBar.open('正しい値を入力してください。', null, {
      //     duration: 2000,
      //   });
      //   return false
      // } 
    } else {
      this.snackBar.open('正しい値を入力してください。', null, {
        duration: 2000,
      });
      return false;
    }
     
    if(!formVal.message ) {
      this.snackBar.open('正しい値を入力してください。', null, {
        duration: 2000,
      });
      return false;
    }

    var temp_sto = ''
    this.items.forEach(function (value) { 

      if(formVal.item_selected == value.item_id) {
        temp_sto = value;
      }
    });

    if(!formVal.type) {
      this.snackBar.open('正しい値を入力してください。', null, {
        duration: 2000,
      });
      return false;
    }
 
    this.maintenanceData.data = { 
      'item_selected' : temp_sto,   
      'message' : formVal.message, 
      'type' : formVal.type 
    }
 
    this.router.navigate(['/main/maintenance/confirm/' + this.store.store_no ]);
  }

}