import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carrier-third-cat-management',
  templateUrl: './carrier-third-cat-management.component.html',
  styleUrls: ['./carrier-third-cat-management.component.scss']
})
export class CarrierThirdCatManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
