import { Injectable } from '@angular/core';

import { MatSidenav } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private sidenav: MatSidenav;

  public isToggleSidenav: boolean = false;
  public menuVisibility: boolean;

  constructor() { }

  toggleSideNav() {
    this.isToggleSidenav = !this.isToggleSidenav;

    if (this.isToggleSidenav) {
      this.sidenav.open();
    } else {
      this.sidenav.close();
    }
  }

  getSideNavStatus(): boolean {
    return this.isToggleSidenav;
  }

  setSideNav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  getSidenav() {
    return this.sidenav;
  }

  closeSidenav() {
    this.isToggleSidenav = false;
    this.sidenav.close();
  }

  setMenuVisible(visibility: boolean) {
    this.menuVisibility = visibility;
  }
}
