import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class ResetCompleteComponent implements OnInit {

  logo: string = "G-Retail";
  title: string = 'パスワードをリセットする';
  subtitle: string = 'パスワードをリセットしました。';

  constructor() { }

  ngOnInit() {
  }

}
