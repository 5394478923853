import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Items } from '../../item-management/shared/item.model';
import { StoreSecondCatlist } from '../../store-second-cat-management/shared/store-second-cat.model';

@Component({
  selector: 'app-store-second-cat-view',
  templateUrl: './store-second-cat-view.component.html',
  styleUrls: ['./store-second-cat-view.component.scss']
})
export class StoreSecondCatViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<StoreSecondCatViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StoreSecondCatlist) { }

  ngOnInit() {
  }

}
