import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import { VenderService } from '../shared/vender.service';

export interface KeyValue {
  key: string;
  value: string;
}


@Component({
  selector: 'app-vender-opt',
  templateUrl: './vender-opt.component.html',
  styleUrls: ['./vender-opt.component.scss']
})
export class VenderOptComponent implements OnInit {

  categForm: FormGroup;
  opt: string
  disabledSubmit: boolean = false;
  utype: KeyValue[] = [
    { key: "av", value: "Disabled" },
    { key: "un", value: "Active" }
  ];

  utypeControl = new FormControl('', [Validators.required]);
  isLoad = false
  showLoading = false
  showErrorText = false
  errorText = ''

  constructor(
    public dialogRef: MatDialogRef<VenderOptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder,
    private service: VenderService
    ) 
    { }

    ngOnInit() {
      if(this.data.option === 'edit') 
        this.opt = '変更'
      else this.opt = 'ベンダー新規追加'
      this.createForm();
      console.log(this.data)
    }

    createForm(): void {
      this.categForm = this.formBuilder.group({
        name: [ this.data.name, Validators.required ],
        address: [ this.data.address, Validators.required ],
        tel: [ this.data.tel, Validators.required ]
      });
    }

    onSubmit(){
      this.isLoad = true
      this.showLoading = true
      if (this.categForm.invalid) {
        this.showLoading = false
        this.showErrorText = true
        this.errorText = '重要なフィールドを記入してください' //Please fill impt fields
        return
      }
      // this.disabledSubmit = true;
      let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
      let val: any = this.categForm.getRawValue()
  
      let body: any;
      if(this.data.option === 'add')
        body = { 
          'name': val.name,
          'address': val.address,
          'tel': val.tel,
          'filename': "default",
          'created_by': tmp.userid 
        }
      else if(this.data.option === 'edit')
        body = { 
          'id': this.data.id,
          'name': val.name,
          'address': val.address,
          'tel': val.tel,
          'filename': "default",
          'updated_by': tmp.userid  
        }
  
      this.service.venderOpt(body, this.data.option)
      .subscribe(res => {
        if(res){
          this.dialogRef.close(true)
        }else{
          this.showLoading = false
          this.showErrorText = true
          this.errorText = '接続の問題が再試行してください'
        }
      })
    }
  
    cancelSave(){
      this.isLoad = false
      this.showLoading = false
      this.showErrorText = false
    }
    
}
