import { BadgeSocketService } from 'src/app/shared/badge-socket.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Collection } from '../ordered-item.model';
import { DashboardService } from '../dashboard.service';
import { CollectionService } from '../../../collection/shared/collection.service';
import { FormControl, FormGroup, FormBuilder ,Validators} from '@angular/forms';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-coll-confirm',
  templateUrl: './coll-confirm.component.html',
  styleUrls: ['./coll-confirm.component.scss']
})
export class CollConfirmComponent implements OnInit {
  colltime: string[] = ['指定なし', '午前','午後']

  constructor(
    public badgeService: BadgeSocketService,
    public dialogRef: MatDialogRef<CollConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DashboardService,
    private collService: CollectionService,
    private formBuilder: FormBuilder,
  ) { }

  cc_confirm: boolean;
  wh_input: boolean;
  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  ngOnInit() {
    this.createForm()
    this.cc_confirm = true;
    this.wh_input = true;
    console.log('ngOnInit', this.data)
  }

  CollectionData: any
  confirmCollection(data: any) {

    console.log('cc confirm', data)

    if(this.data.type === 'cc'){

      let raw = this.collform.getRawValue();

      if(!raw.cc_comment){
        console.log('required cc comment');
        return;
      }

      let body: any;
      body = {
        'id': this.data.mdata.id,
        'user_id': this.tmp.userid,
        'cc_comment': raw.cc_comment
      }
      
      this.collService.updateCollection(body)
      .subscribe(res => {
        body = {
          'head': 2,
          'reference': this.data.mdata.coll_ref_no,
          'type' : 3,
          'user_type': this.data.mdata.created_by,
          'user': this.tmp.userid,
          'level': 3,
          'status': 0,
          'user_id': this.data.mdata.created_by,
          'cc_view': 2,
          'hd_view': 0,
          'wh_view': 2,
          'rr_view': 2,
        }
        
        this.service.saveTask(body)
        .subscribe(ret => {
          this.dialogRef.close(true) 
        })
      })
      return
    }
    
    this.CollectionData = data
 
    let raw = this.collform.getRawValue();

    if(!raw.provider_name){
      console.log('required');
      return;
    }
    
    let accDate = raw.coll_date.length == 0 ? '1970-01-01' : formatDate(new Date(raw.coll_date), 'yyyy-MM-dd', 'en-US')
    let body: any;
    body = {
      'provider_name': raw.provider_name,
      'driver_name': raw.driver_name,
      'driver_contact_number': raw.driver_contact_number,
      'coll_date': accDate,
      'coll_time': raw.coll_time,
      'user_id': this.tmp.userid,
      'id': this.data.mdata.id,
      'coll_ref_no': this.data.mdata.coll_ref_no
    }
    
    this.collService.updateCollection(body)
    .subscribe(res => {
      let view_tmp = {
        'cc': true,
        'hd': false,
        'wh': false,
        'rr': false,
        'gg': false,
        'vr': false
      }
      //this.badgeService.pushBadgeNotif(view_tmp)
      this.dialogRef.close(true)
    })

  }
  collform: FormGroup;
  createForm() {
    this.collform = this.formBuilder.group({
      provider_name: [ ''],  
      driver_name: [''],
      driver_contact_number: [''],
      coll_date: [''],
      coll_time: [''],
      cc_comment: ['']
    })
  }
}
