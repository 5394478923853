import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Items } from '../../item-management/shared/item.model';
import { CarrierFirstCatlist } from '../../carrier-first-cat-management/shared/carrier-first-cat.model';

@Component({
  selector: 'app-carrier-first-cat-view',
  templateUrl: './carrier-first-cat-view.component.html',
  styleUrls: ['./carrier-first-cat-view.component.scss']
})
export class CarrierFirstCatViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CarrierFirstCatViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CarrierFirstCatlist) { }

  ngOnInit() {
  }

}
