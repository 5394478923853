import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef, MatTableDataSource} from '@angular/material';
import { DataService, storeUrl }  from '../../../shared/data.service';
import { Router } from '@angular/router';
import { Store } from '../../shared/store.model';
import { StoreService } from '../../shared/store.service';
import { InquiryData } from '../../../inquiry/shared/inquiry.prov';



@Component({
  selector: 'app-confirm-store-dialog',
  templateUrl: './confirm-store-dialog.component.html',
  styleUrls: ['./confirm-store-dialog.component.scss']
})
export class ConfirmStoreDialogComponent implements OnInit {

  store: Store[] = [];
  dataSource: MatTableDataSource<Store>;

  constructor(
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data : any,
    public matDialogRef : MatDialogRef<ConfirmStoreDialogComponent>,
    private service: StoreService,
    private router: Router,
    private inquiryData: InquiryData,
  ) { }

  ngOnInit() {
    console.dir(this);
  }

  ok(id,url) {
    this.matDialogRef.close();
    this.router.navigate(['main/' + url + '/input/' + id]);
    return;
  }
}
