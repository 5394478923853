import { CarrierService } from './../shared/carrier.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as prefectures from './city_pref.json'
import * as _moment from 'moment';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { CarrierFirstCatlist } from '../../carrier-first-cat-management/shared/carrier-first-cat.model';
import { CarrierSecondCatlist } from '../../carrier-second-cat-management/shared/carrier-second-cat.model';
import { CarrierThirdCatlist } from '../../carrier-third-cat-management/shared/carrier-third-cat.model';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

export class customValidationService {
  static checkLimit(min: number, max: number): ValidatorFn {
   return (c: AbstractControl): { [key: string]: boolean } | null => {
       if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
           return { 'range': true };
       }
       return null;
   };
 }
}

export interface KeyValue {
  key: any;
  value: string;
}



@Component({
  selector: 'app-carrier-opt',
  templateUrl: './carrier-opt.component.html',
  styleUrls: ['./carrier-opt.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CarrierOptComponent implements OnInit {

  carrierForm: FormGroup;

  date = new FormControl(moment());

  opt: string

  isLoad = false
  showLoading = false
  showErrorText = false
  errorText = ''

  prefs: KeyValue[] = []
  city: KeyValue[] = []
  area:  KeyValue[] = []
  areatext = "";

  pref = prefectures.default[0]
  disabledSubmit: boolean = false;
  first_categ: CarrierFirstCatlist[] = [];
  second_categ: CarrierSecondCatlist[] = [];
  third_categ: CarrierThirdCatlist[] = [];

  constructor(public dialogRef: MatDialogRef<CarrierOptComponent>, private service: CarrierService,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder) { }

  
  ngOnInit() {
    
    if(this.data.option === 'edit') this.opt = '変更'
    else this.opt = '店舗新規追加'

    console.log(this.data)
    for(let x = 1; x <= 48; x++){
      if(x < 10) { 
        this.prefs.push({key: "0"+x, value: this.pref["0"+x].name})
        this.area.push({key: "0"+x, value: this.pref["0"+x].area})
      }
      else {
        this.prefs.push({key: ""+x, value: this.pref[""+x].name})
        this.area.push({key: ""+x, value: this.pref[""+x].area})
      }
    }
    this.first_categ = this.data.carrier_first_cat
    this.second_categ = this.data.carrier_second_cat
    this.third_categ = this.data.carrier_third_cat
    this.createForm()

  }

  popCity: boolean = false

  showCity(event: any, key: string){
    
    if(this.data.option === 'add') this.popCity = true
    if(event.isUserInput){
      if(this.popCity){
        this.city = undefined
        this.city = []
        for(let x = 0; x < this.pref[key].city.length; x++){
          let temp = this.pref[key].city[x]
          this.city.push({ key: temp.citycode, value: temp.city })
        }

        this.areatext = this.pref[key].area 
        console.log(this.pref[key])
      }
      this.popCity = true
    }
  }


  onSubmit(){
    this.isLoad = true
    this.showLoading = true
    let raw = this.carrierForm.getRawValue()
    console.log(this.carrierForm.invalid, this.date.invalid)
    if (this.carrierForm.invalid || this.date.invalid) {
      this.showLoading = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    };
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body: any
    if(this.data.option === 'add'){
      body = {
        'created_by': tmp.userid,
        'unique_id' : raw.unique_id, 
        'carrier_id' : raw.carrier_id, 
        'ice_id': raw.ice_id,
        'carrier_no' : raw.carrier_no,
        'store_code' : raw.store_code, 
        'store_name': raw.store_name,
        'store_name_kana': raw.store_name_kana,
        'first_cat': Number(raw.first_cat.id),
        'second_cat': Number(raw.second_cat.id),
        'third_cat': Number(raw.third_cat.id),
        'ship_name': raw.ship_name,  
        'area': this.areatext,
        'pref': raw.pref.value,
        'city': raw.city.value,
        'post_code': raw.post_code,
        'address': raw.address,
        'tel': raw.tel,
        'cr_type': raw.cr_type != null ? raw.cr_type : 0,
        'first_agent_name': raw.first_agent_name,
        'company_name': raw.company_name,
        'owners_evaluation': raw.owners_evaluation,
        'loc_type': raw.loc_type,
        'ho_name': raw.ho_name,
        'sq_rank': raw.sq_rank,
        'ts_rank': raw.ts_rank,
        'tt_type': raw.tt_type,
        'training': raw.training,
        'remark1': raw.remark1,
        'remark2': raw.remark2,
        'loc_station': raw.loc_station,
        'population': raw.population != null ? raw.population : 0,
        'closed_flg': raw.closed_flg == false || raw.closed_flg == null ? 0 : 1, 
        'sa_flg': raw.sa_flg,
        'retail_tier': raw.retail_tier,
      }
      console.log(body)
    }else if(this.data.option === 'edit'){
      body = {
        'updated_by': tmp.userid,
        'id': this.data.desc.id,
        'unique_id' : raw.unique_id, 
        'carrier_id' : raw.carrier_id, 
        'ice_id': raw.ice_id,
        'carrier_no' : raw.carrier_no,
        'store_code' : raw.store_code, 
        'store_name': raw.store_name,
        'store_name_kana': raw.store_name_kana,
        'first_cat':raw.first_cat.id,
        'second_cat': raw.second_cat.id,
        'third_cat': raw.third_cat.id,
        'ship_name': raw.ship_name,  
        'area': this.areatext,
        'pref': raw.pref.value,
        'city': raw.city.value,
        'post_code': raw.post_code,
        'address': raw.address,
        'tel': raw.tel,
        'cr_type': raw.cr_type != null ? raw.cr_type : 0,
        'first_agent_name': raw.first_agent_name,
        'company_name': raw.company_name,
        'owners_evaluation': raw.owners_evaluation,
        'loc_type': raw.loc_type,
        'ho_name': raw.ho_name,
        'sq_rank': raw.sq_rank,
        'ts_rank': raw.ts_rank,
        'tt_type': raw.tt_type,
        'training': raw.training,
        'remark1': raw.remark1,
        'remark2': raw.remark2,
        'loc_station': raw.loc_station,
        'population': raw.population != null ? raw.population : 0,
        'closed_flg': raw.closed_flg == false || raw.closed_flg == null ? 0 : 1, 
        'sa_flg': raw.sa_flg,
        'retail_tier': raw.retail_tier,
      }
    }
    this.service.carrierOpt(body, this.data.option)
    .subscribe(res => {
      if(res){
        let mres: any = res
        if(mres.body.success)
          this.dialogRef.close(true)
        else{
          this.showLoading = false
          this.showErrorText = true
          this.errorText = mres.body.error
        }
      }else{
        this.showLoading = false
        this.showErrorText = true
        this.errorText = '接続の問題が再試行してください'
      }
    })
  }

  cancelSave(){
    this.isLoad = false
    this.showLoading = false
    this.showErrorText = false
  }

  createForm(): void {
    let cityObj
    let prefObj
    let prefKey: string
    let first_catId: number
    let second_catId: number
    let third_catId: number

    if(this.data.option === 'edit'){
      for(let x = 0; x < this.prefs.length; x++)

        if(this.prefs[x].value === this.data.desc.pref){
          prefObj = x
          prefKey = this.prefs[x].key
        }
  
      if(this.data.desc.pref === "" || this.data.desc.pref === null || this.data.desc.pref === " "){
          prefObj = 47
          prefKey = this.prefs[47].key
      } 

      for(let x = 0; x < this.pref[prefKey].city.length; x++){
        let temp = this.pref[prefKey].city[x]
        this.city.push({ key: temp.citycode, value: temp.city })
      }
  
      for(let x = 0; x < this.city.length; x++){
        if(this.data.desc.city === this.city[x].value){
          cityObj = x
        }
      }
      if(this.data.desc.city === "" || this.data.desc.city === null || this.data.desc.city === " " ){
        cityObj = 0
      } 

      for (let x = 0; x < this.first_categ.length; x++) {
        if (this.first_categ[x].id === this.data.desc.first_cat) first_catId = x
      }

      for (let x = 0; x < this.second_categ.length; x++) {
        if (this.second_categ[x].id === this.data.desc.second_cat) second_catId = x
      }

      for (let x = 0; x < this.third_categ.length; x++) {
        if (this.third_categ[x].id === this.data.desc.third_cat) third_catId = x
      }
   
    }

    
    let raw = this.data.desc

    var valclosed_flg = false
    if (raw.closed_flg == 0 ||  raw.closed_flg == null){
      valclosed_flg = false
    } else if (raw.closed_flg == 1){ 
      valclosed_flg = true
    }
    this.areatext = raw.area
    this.carrierForm = this.formBuilder.group({
      unique_id: [ raw.unique_id, [Validators.required] ],
      carrier_id: [ raw.carrier_id, [Validators.required] ],
      ice_id: [ raw.ice_id, [Validators.required] ],
      carrier_no: [ raw.carrier_no, [Validators.required] ],
      store_code: [ raw.store_code, [Validators.required] ],
      store_name: [ raw.store_name, [Validators.required] ],
      store_name_kana: [ raw.store_name_kana, [Validators.required] ],
      first_cat: [this.data.option === 'edit' ? this.first_categ[first_catId] : undefined, Validators.required],
      second_cat: [this.data.option === 'edit' ? this.second_categ[second_catId] : undefined, Validators.required],
      third_cat: [this.data.option === 'edit' ? this.third_categ[third_catId] : undefined, Validators.required],
      ship_name: [ raw.ship_name ],
      area: [ this.data.option === 'edit' ? this.areatext : ''  ],
      pref: [ this.data.option === 'edit' ? this.prefs[prefObj] : '', Validators.required ],
      city: [ this.data.option === 'edit' ? this.city[cityObj] : '', Validators.required ],
      post_code: [ raw.post_code, [Validators.required ]],
      address: [ raw.address, [Validators.required] ],
      tel: [ raw.tel ],
      cr_type: [ raw.cr_type ? raw.cr_type : 'N/A' , Validators.required ],
      first_agent_name: [ raw.first_agent_name],
      company_name: [ raw.company_name],
      owners_evaluation: [ raw.owners_evaluation],
      loc_type: [ raw.loc_type,  ],
      ho_name: [ raw.ho_name],
      sq_rank: [ raw.sq_rank],
      ts_rank: [ raw.ts_rank],
      tt_type: [ raw.tt_type],
      training: [ raw.training],
      remark1: [ raw.remark1 ],
      remark2: [ raw.remark2 ],
      loc_station: [ raw.loc_station ],
      population: [ raw.population ],
      closed_flg: [ valclosed_flg ],
      sa_flg: [ raw.sa_flg ? raw.sa_flg : 'あり', Validators.required ],
      retail_tier: [ raw.retail_tier ],
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }  


}
