import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sent-email',
  templateUrl: './sent-email.component.html',
  styleUrls: ['./sent-email.component.scss']
})
export class SentEmailComponent implements OnInit {

  logo: string = "G-Retail";
  title: string = 'パスワードをリセットする';
  subtitle: string = '確認メールを送信しました。';

  constructor() { }

  ngOnInit() {
  }
}
