import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DashboardService } from './shared/dashboard.service';
import { DateModule } from '../shared/date.module';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ DashboardService ],
})
export class DashboardComponent implements OnInit {
  title: string = 'Dashboard';

  constructor(
    private dateModule: DateModule,
    public service: DashboardService) { }

  ngOnInit() {
   
  }
}
