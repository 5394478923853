import { Injectable } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { HttpRequest, HttpClient, HttpEvent, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { finalize, map, tap } from 'rxjs/operators';

import { ImageToolsComponent } from '../image-tools.component';
import { DataService, imagesUrl } from '../../data.service';

@Injectable({
  providedIn: 'root'
})
export class ImageToolsService {

  uploadPercent: Observable<number>;
  componentName: string = '';

  image: Image = null;
  // for multiple images
  images: Image[] = [];

  constructor(
    private bottomSheet: MatBottomSheet,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient) { }

  openImageToolsComponent(isMultiFile: boolean) {
    const bottomSheetRef = this.bottomSheet.open(ImageToolsComponent, {
      ariaLabel: 'Upload image',
      data: { multifile: isMultiFile }
    });

    return bottomSheetRef.afterDismissed();
  }

  setDownloadableURL(url: string) {
    this.image = new Image();
    this.image.image_url = url;
  }

  setDownloadablesURL(urls: any[]) {
    this.images = [];
    for (let url of urls) {
      let image = new Image();
      image.image_url = url;
      this.images.push(image);
    }
  }

  uploadImage(body: any) {

    const formData: FormData = new FormData()
    formData.append('image_name', body.image_name, body.image_name.name)
    formData.append('image_type', body.image_type)
    const req = new HttpRequest('POST', imagesUrl + 'upload', formData, {
      reportProgress: true,
      withCredentials: true,
    })
    return this.dataService.request(imagesUrl + 'upload', formData);
  }

  getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return 'Uploading data...'
      
      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total)
        return `${percentDone}`

      case HttpEventType.Response:
         return event.body;

      default:
        return `surprising upload event: ${event.type}.`;
    }
  }
}

export class Image {
  image_name: any;
  image_type: string;
  image_url: string;
}
