import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DashboardService } from '../dashboard.service';
import { Location, formatDate } from '@angular/common';
import { map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { MatRadioChange } from '@angular/material';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector      : 'app-maintenance-option'
  , templateUrl : './maintenance-option.component.html'
  , styleUrls   : ['./maintenance-option.component.scss'],
  providers: [ BadgeSocketService ]
})
export class MaintenanceOptionComponent implements OnInit {

  constructor(
    public badgeService: BadgeSocketService,
    public dialogRef: MatDialogRef<MaintenanceOptionComponent>
    , private service: DashboardService
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private formBuilder: FormBuilder
  ) { }
  
  maintenanceForm : FormGroup;
  fileimages : any;

  initData = {
    head           : 0
    , usertype     : this.data.userType
    , finishedCall : false
    , buttonTxt    : '登録' //'メンテナンス内容確認'
  };

  hours=[]

  venderData : any;
 
  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'));
  
  ngOnInit() {
    console.log(this.data)
    this.getTaskByRef(this.data.mData.ref_code); 
    if(this.initData.usertype == 'hd'){
      this.service.getVenders().subscribe(res => {
        if(res.success) {
          this.venderData = res.payload.venders; 
        }
      })
    } 

    this.createForm();

    if(this.data.mData.images){
      let a = this.data.mData.images;
      let b = a.replace('[','');
      let c = b.replace(']','');
      this.service.maintenanceGetimages({ ids : c }).subscribe(res => {
        if(res.success){
          this.fileimages = res.payload;
        }
      });
    }

    for (let i = 0; i < 24; i++) {
      let ii = ("0" + i).slice(-2); 
      this.hours.push(ii);
    } 
  }

  maintenanceTimeFlg(data){
    if(data !== null) {
      return ("0" + data).slice(-2)
    } else {
      return false;
    } 
  }

  hdoption_keyclicked : any;
  showVenders(event: MatRadioChange, key: string){
    if (event.source.value) {
      if(key === 'Accept'){
        this.hdoption_keyclicked = false;
        // this.maintenanceForm.controls['notes'].setValidators([Validators.required])
        // this.maintenanceForm.controls['vender_id'].setValidators([Validators.required])
      } else { 
        this.hdoption_keyclicked = true;    
      } 
      console.log(key)
    }
  }

  localUrl      = '';
  localFile     = '';
  localFilename = '';
  isUploading   = false;

  onSelectFile(event) { 
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
          this.localUrl = event.target.result; 
      }
      reader.readAsDataURL(event.target.files[0]);
      this.localFile = event.target.files[0];
      this.localFilename = event.target.files[0].name;
    } 
  }

  getTaskByRef(id){
    this.service.getTaskByRef(id).subscribe(res => {
      if(res.success) {
        let response = res.payload.task_records[0]; 
        this.initData.head = response.head;
        if(this.initData.usertype == 'cc'){
          //if(response.head == 2 && response.level == 2){
            //this.initData.finishedCall = true;
          //  this.initData.buttonTxt = '完了' //2nd time Reject
          //}    
          if(response.head == 2) { //} && response.level == 3){
            this.initData.finishedCall = true;  
            this.initData.buttonTxt = 'ショップへ連絡済' //Finished Call
          }else if(response.head == 5) { 
            this.initData.buttonTxt = '完了を確認' //'Confirm Finish'
          } 
        } else if (this.initData.usertype == 'vr') {
          if(response.head == 3) {
            this.initData.buttonTxt = '訪問日時確定' //'Fix Datetime 
          } else if(response.head == 4) {
            this.initData.buttonTxt = '結果反映' //Update Status
          }
        }
      }
    }); 
   
  }
 
  createForm(): void {  
      this.maintenanceForm = this.formBuilder.group({
        notes              : []
        , type             : []
        , vender_id        : [Validators.required]
        , maintenance_date : []
        , maintenance_time : []
        , finish_date      : []
      });  
  }

  onSubmit(){  
    let formVal = this.maintenanceForm.getRawValue();  
    let head = this.initData.head; 
    let userType = this.initData.usertype;
    let dataToSave:any;

    if(userType == 'cc') { 
      dataToSave = this.cc(formVal, head);
      // validations
      let errflg = false;
      if(this.initData.head == 5){
        errflg = false; 
      } else if(this.initData.head == 2) {
        if(this.data.mData.judgement_hd == 2 && !formVal.notes){
          errflg = true
        } else {
          errflg = false;
        }
      } else if (this.initData.head == 0 ){ 
        if(!formVal.notes || formVal.type === null){ 
          errflg = true;
        }
      } else if (this.initData.head == 1) {
        if(!formVal.notes || formVal.type === null){ 
          errflg = true;
        }
      }


      if(errflg){
       console.log('Required');
       return false;
      } 
  

    } else if(userType == 'hd') { 
      dataToSave = this.hd(formVal, head); 

      let errFlg = false
      if(formVal.type === null){
        errFlg = true;
      } else { 
        if(formVal.type == 2 && !formVal.notes){
          errFlg = true;
        } else if(formVal.type == 1) {
          console.log(typeof formVal.vender_id)
          if(!formVal.notes || typeof formVal.vender_id === 'function') {
            errFlg = true;
          }
        }
      }

      if(errFlg){ 
        console .log("required");
        return false;
       }

    } else if(userType == 'vr') {
      dataToSave = this.vr(formVal, head);
      // validations
      if(this.initData.head == 3) {
        if(!formVal.maintenance_date || !formVal.maintenance_time){
          console.log('required')
          return false;
        }
      } else { 

        if(!formVal.finish_date || !formVal.notes || !this.localFile){
          console.log('required')
          return false;
        } 
      } 
 
    }  

    let merged = {
      ...this.data.mData,
      ...dataToSave,
      ...{ user_id : this.tmp.userid }
    };  
  
    if(this.initData.head == 4){
      if(this.localFile) {
        this.isUploading = true;
        let raw = this.maintenanceForm.getRawValue(); 
        
        raw.source_id = 3;
        raw.created_by = this.tmp.userid; 
        raw.file_description = raw.notes;
        
        this.service.fileUpload(raw, this.localFile).pipe(
          map(event => {
            console.log(event)
            switch (event.type) {
              case HttpEventType.Sent:
                console.log('Uploading data...')
                return 
              case HttpEventType.Response: 
                let res : any =  event.body;
                let a = res.payload.filename;
                let merged2 = {
                    ...merged, 
                    ...{ pdf : a }
                  }

                  this.service.maintenanceTaskrecords(merged2, merged.id).subscribe(res=> { 
                    if(res.success) {
                      let view_tmp = {
                        'cc': dataToSave.cc_view == 1,
                        'hd': dataToSave.hd_view == 1,
                        'wh': true,
                        'rr': false,
                        'gg': false,
                        'vr': dataToSave.vr_view == 1
                      }
                      //this.badgeService.pushBadgeNotif(view_tmp)
                      this.isUploading = false; 
                      this.dialogRef.close(true)
                    }
                  }) 
                return
            }
          })
        ).subscribe(res => {
          
        }) 
      }
    } else {
      this.service.maintenanceTaskrecords(merged, merged.id).subscribe(res=> { 
        if(res.success) {
          this.dialogRef.close(true)
        }
      })
    } 
    
  } 

  cc(formVal, head){  
    let dataToSave = {}
    if(head == 0 ) {
      if(formVal.type == 1 ) { // judgement by cc possible
        dataToSave = {
          head           : 1
          , level        : 2
          , status       : 1
          , cc_view      : 2
          , hd_view      : 1
          , vr_view      : 0
          , judgement_cc : 1
          , comment_cc   : formVal.notes
        }
      } else if( formVal.type == 0 ){ // judgement by cc impossibe
        dataToSave = {
          head : 0
          , level        : 1
          , status       : 0
          , cc_view      : 2
          , hd_view      : 0
          , vr_view      : 0
          , judgement_cc : 0
          , close_reason : formVal.notes
        }
      }
    } else if (head == 1) {
      if(formVal.type == 0 ) { // if rejected -> close reason by cc
        dataToSave = {
          head           : 1
          , level        : 2
          , status       : 0
          , cc_view      : 2
          , hd_view      : 2
          , vr_view      : 0
          , judgement_cc : 3
          , close_reason : formVal.notes
        }
      } else if( formVal.type == 1 ){ // if rejected -> re req by cc 
        dataToSave = {
          head           : 2
          , level        : 2
          , status       : 1
          , cc_view      : 2
          , hd_view      : 1
          , judgement_cc : 2
          , vr_view      : 0
          , re_req       : formVal.notes
        }
      }
    } else if (head == 2){
      if(this.data.mData.judgement_hd == 2) {
        dataToSave = {
          head           : 2
          , level        : 3
          , status       : 0
          , cc_view      : 2
          , vr_view      : 2
          , hd_view      : 2
          , judgement_cc : 3
          , close_reason : formVal.notes
        }
      } else {
        dataToSave = {
          head           : 3
          , level        : 4
          , status       : 1
          , cc_view      : 1
          , hd_view      : 2
          , vr_view      : 1
          , close_reason : formVal.notes
        }
      }
      
    } else if (head == 5){
      dataToSave = {
        head      : 6
        , level   : 7
        , status  : 0
        , cc_view : 2
        , hd_view : 2
        , vr_view : 2
      }
    }  
    return dataToSave;
  }

  hd(formVal, head){ 
    let dataToSave = {}
    if(formVal.type == 1) { // judgement by hd if. accepted and input vr
      dataToSave = {
        head           : 2
        , level        : 3
        , status       : 1
        , cc_view      : 1
        , hd_view      : 2
        , vr_view      : 0
        , judgement_hd : 1
        , comment_hd   : formVal.notes
        , vender_id    : formVal.vender_id
      }
    } else if( formVal.type == 2){ // judgement by hd rejected
      if(head == 2) {
        dataToSave = {
          head           : 2
          , level        : 2
          , status       : 0
          , cc_view      : 2
          , hd_view      : 2
          , vr_view      : 0
          , judgement_hd : 2
          , comment_hd : formVal.notes
        }
      } else {
        dataToSave = {
          head           : 1
          , level        : 2
          , status       : 1
          , cc_view      : 1
          , hd_view      : 2
          , vr_view      : 0
          , judgement_hd : 2
          , comment_hd   : formVal.notes
        }
      }
      
    }   
    return dataToSave; 
  }

  vr(formVal, head){
    let dataToSave = {}
    if(head == 3){
      dataToSave = {
        head        : 4
        , level     : 5
        , status    : 1
        , cc_view   : 1
        , hd_view   : 2
        , vr_view   : 1
        , maintenance_date : formatDate(new Date(formVal.maintenance_date), 'yyyy-MM-dd', 'en-US')
        , maintenance_time : formVal.maintenance_time
      }
    } else if (head == 4) {
      dataToSave = {
        head               : 5
        , level            : 6
        , status           : 1
        , cc_view          : 1
        , hd_view          : 2
        , vr_view          : 2
        , finish_date      : formatDate(new Date(formVal.finish_date), 'yyyy-MM-dd', 'en-US')
        , pdf              : formVal.pdf
        , final_comment_vr : formVal.notes
      }
    } 
    return dataToSave; 
  }

}
