export class Collection {
  store_id: string;
  remarks: string;
  address: string;
  shop_incharge: string;
  shop_date_hopes: string;
  urg_flg: boolean;
  provider_name: string;
  driver_name: string;
  driver_contact_number: string;
  coll_date: string;
  coll_time: string;
  phone_number: string;
}