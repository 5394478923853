import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { StoreThirdCatlist } from '../shared/store-third-cat.model';
import { StoreThirdCatService } from '../shared/store-third-cat.service';
import { StoreThirdCatViewComponent } from '../store-third-cat-view/store-third-cat-view.component';
import { StoreThirdCatOptComponent } from '../store-third-cat-opt/store-third-cat-opt.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-store-third-cat-list',
  templateUrl: './store-third-cat-list.component.html',
  styleUrls: ['./store-third-cat-list.component.scss']
})
export class StoreThirdCatListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  categs: StoreThirdCatlist[] = [];
  dataSource: MatTableDataSource<StoreThirdCatlist>;
  displayedColumns = ['id', 'name', 'note', 'viewAction', 'editAction', 'deleteAction'];
  pbVisible: boolean = false;

  constructor(public service: StoreThirdCatService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.categs);
    this.getCateg();
    this.dataSource.sort = this.sort;
  }

  getCateg(){
    this.service.getThirdCategList().subscribe(res => {
      this.pbVisible = true
      if(res.success){
        this.categs = res.payload.store_third_cat
        console.log(this.categs)
        if(this.categs.length !== 0){
          this.dataSource.data = this.categs
          this.dataSource.filterPredicate = (data: StoreThirdCatlist, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.name, data.note]

            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      }
    })
  }




  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDiaog(rowData: StoreThirdCatlist){
    this.dialog.open(StoreThirdCatViewComponent, { width: '980px', height:'550px',
    data: rowData })
  }

  openOptionDialog(opt: string, val: StoreThirdCatlist){
    const dialogRef = this.dialog.open(StoreThirdCatOptComponent, { width: '980px', height:'550px',
    data: {
      option: opt,
      id: val.id, 
      name: val.name,
      note: val.note, 
    },
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getCateg()
    })
    
  }
  openOptionDialogAdd(opt: string){
    const dialogRef = this.dialog.open(StoreThirdCatOptComponent, { width: '980px', height:'550px',
    data: {
        option: opt, id: '', 
        name: '', note: ''
      },
      disableClose: true 
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getCateg()
    })
  }

  onDelete(val: StoreThirdCatlist){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        name: val.name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'id': val.id,
          'user_id': tmp.userid 
        }
        this.service.saveCateg(body, 'delete')
        .subscribe(res => {
          this.getCateg()
        })
      }
    })
  }



}
