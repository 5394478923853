import { Maintenance } from './../shared/inbound.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { InboundService } from '../shared/inbound.service';
import * as status_message from '../shared/status_message.json'

@Component({
  selector: 'app-inbound-view-mainte',
  templateUrl: './inbound-view-mainte.component.html',
  styleUrls: ['./inbound-view-mainte.component.scss']
})
export class InboundViewMainteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InboundViewMainteComponent>, private inboundservice: InboundService,
    @Inject(MAT_DIALOG_DATA) public data: Maintenance)
     { }
  
  fileimages : any;
  mainte_type: string[] = ['','修理','交換','調査','その他']
  status_msg = status_message.default[0]
  ngOnInit() {
    console.log('asdfasdfasdf', this.data)
    if(this.data.images){
      let a = this.data.images;
      let b = a.replace('[','');
      let c = b.replace(']','');
      this.inboundservice.maintenanceGetimages({ ids : c}).subscribe(res => {
        if(res.success){
          this.fileimages = res.payload;
        }
      });
    }
  } 

  maintenanceTimeFlg(data){
    if(data !== null) {
      return ("0" + data).slice(-2)
    } else {
      return false;
    } 
  }

  getMaintenanceStatus(head:Number,level:Number,task_status:Number,comment_hd:string){
    for(let x = 0; x < this.status_msg["Maintenance"].length; x++){
      let col = this.status_msg["Maintenance"][x]
      if ( col['level'] == level && col['head'] == head && col['status'] == task_status){
        //return col['message']

        if (head == 1 && level == 2 && task_status == 1 && (comment_hd == '' || comment_hd == null)) {  
          if ( col['level'] == level && col['head'] == head && col['status'] == task_status && col['hd_view'] == 1){
            return col['message']
          }
        }
        else if (head == 1 && level == 2 && task_status == 1 && comment_hd != '') {
          if (col['level'] == level && col['head'] == head && col['status'] == task_status && col['hd_view'] == 2 ){
            return col['message'] 
          }
        }
        else { 
          return col['message']
        }
      }
    }
  } 

}
