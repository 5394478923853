import { Injectable } from '@angular/core';

@Injectable()
export class OrderData {
    public order: any;
    public flag: boolean;
    public store: any;
    public stores: any;
    public ref_code: string;
    public order_desc: any
    public constructor() { }
}

export class RelationalRefData {
    public ref_code: string =  '';
    public inq_ref: string = '';
    public order_ref: string = ''
    public mainte_ref: string = ''
    public coll_ref: string = ''
    public updID: number = null
    public constructor() {}
}
