import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResetPasswordService } from '../shared/reset-password.service'; 
import { HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ResetPasswordData } from '../shared/reset-password.prov';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  constructor(
    public router : Router, 
    private resetpasswordService: ResetPasswordService,
    private resetPasswordData : ResetPasswordData
   ){ }

  ngOnInit() {
    var cur_url = this.getAbsoluteDomainUrl() + this.router.url;
    this.resetpasswordService.verifyToken(cur_url)
    .pipe(
      map(event => {
        if(event.success){
          
          this.resetPasswordData.data = event;
          this.resetPasswordData.data.url = cur_url;
          this.router.navigate(['/reset-password/edit']);
        } else {
          this.router.navigate(['/']);
        }
       })
     )
    .subscribe() 
  }

  getAbsoluteDomainUrl(): string {
    if (window
        && "location" in window
        && "protocol" in window.location
        && "host" in window.location) {
        return window.location.protocol + "//" + window.location.host;
    }
    return null;
}

}
