import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Vender_list } from '../shared/venders.model';
import { VenderService } from '../shared/vender.service';
import { VenderOptComponent } from '../vender-opt/vender-opt.component';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { VenderViewComponent } from '../vender-view/vender-view.component';


@Component({
  selector: 'app-vender-list',
  templateUrl: './vender-list.component.html',
  styleUrls: ['./vender-list.component.scss']
})
export class VenderListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  venders: Vender_list[] = [];
  dataSource: MatTableDataSource<Vender_list>;

  displayedColumns = [
    'id',
    'name', 
    'tel',
    'address', 
    'viewAction', 'editAction', 'deleteAction',
  ];

  pbVisible: boolean = true;

  constructor(public service: VenderService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.venders);
    this.getVender();
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getVender() {
    this.service.getVenderList().subscribe(res => {
      this.pbVisible = true
      if(res.success){
        this.venders = res.payload.venders
        console.log(this.venders)
        if(this.venders.length !== 0){
          this.dataSource.data = this.venders
          this.dataSource.filterPredicate = (data: Vender_list, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.name, data.address, data.tel]

            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      }
    })
  }

  openOptionDialog(opt: string, val: Vender_list){
    const dialogRef = this.dialog.open(VenderOptComponent, { width: '980px', height:'550px',
    data: {
      option: opt,
      id: val.id, 
      name: val.name, 
      tel: val.tel, 
      address: val.address, 
    },
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getVender()
    })
    
  }

  openOptionDialogAdd(opt: string){
    const dialogRef = this.dialog.open(VenderOptComponent, { width: '980px', height:'550px',
    data: {
        option: opt, 
        id: '', 
        name: '', 
        tel: '', 
        address: '', 
      },
      disableClose: true 
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getVender()
    })
  }

  onDelete(val: Vender_list){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        name: val.name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'id': val.id,
          'deleted_by': tmp.userid 
        }
        this.service.venderOpt(body, 'delete')
        .subscribe(res => {
          this.getVender()
        })
      }
    })
  }

  openDiaog(rowData: Vender_list){
    this.dialog.open(VenderViewComponent, { width: '980px', height:'550px', 
    data: rowData })
  }
}
