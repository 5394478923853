import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';

/*export const MY_FORMAT = {
  display: {
    dateInput: 'MM-DD-YYYY'
  },
};*/

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP'},
    // { provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT } // Use this if you want a custom format.
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS } 
  ]
})
export class DateModule {

  constructor(private adapter: DateAdapter<any>) {

  }

  parse(value: any) {
    return this.adapter.parse(value, DEFAULT_DATE_FORMAT);
  }

  parseWithTime(value: any) {
    return this.adapter.parse(value, DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT);
  }

  format(date: any) {
    return this.adapter.format(date, DEFAULT_DATE_FORMAT);
  }

  formatWithTime(date: any) {
    return this.adapter.format(date, DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT); 
  }

  today() {
    return this.adapter.parse(this.adapter.today(), DEFAULT_DATE_FORMAT);
  }

  compareDate(date1: any, date2: any) {
    return this.adapter.compareDate(date1, date2);
  }

  get datePipe() {
    return 'yyyy/MM/dd';
  }

  getMonthAndYear(date: any) {
    return this.adapter.format(this.adapter.parse(date, DEFAULT_DATE_FORMAT), DEFAULT_MONTH_AND_YEAR_ONLY);
  }
}

// Guidelines https://momentjs.com/docs/
export const DEFAULT_DATE_FORMAT = 'YYYY/MM/DD';
export const DEFAULT_TIME_FORMAT = 'hh:mm a';
export const DEFAULT_MONTH_AND_YEAR_ONLY = 'MMMM/YYYY';