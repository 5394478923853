import { Component, OnInit } from '@angular/core';
//import { CollectionService } from './shared/collection.service';

@Component({
  selector: 'app-workflow_setting',
  templateUrl: './workflow_setting.component.html',
  styleUrls: ['./workflow_setting.component.scss'],
  //providers: [ CollectionService ]
})
export class WorkflowSettingComponent implements OnInit {

  //constructor(public service: CollectionService) { }

  ngOnInit() {
  }
}
