import { Component, OnInit, Inject } from '@angular/core';
import { Store_list } from '../shared/store.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as prefectures from '../store-opt/city_pref.json'

export interface KeyValue {
  key: any;
  value: string;
}

@Component({
  selector: 'app-store-view',
  templateUrl: './store-view.component.html',
  styleUrls: ['./store-view.component.scss']
})
export class StoreViewComponent implements OnInit {

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 999, value: "その他" }
  ]
  
  tierInd: number

  constructor(public dialogRef: MatDialogRef<StoreViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Store_list) { }

  tierlvl: { [key: number]: string } =
  {
      100: '1',
      101: '1+',
      200: '2',
      300: '3',
      999: 'その他',
  };

  ngOnInit() {
    //for(let x = 0; x < this.tierLvl.length; x++){
    //  if(this.tierLvl[x].key === this.data.tier) this.tierInd = x
    //}
  }


}
