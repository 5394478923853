import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Collection } from '../../collection/shared/collection.model';
import { Store_list } from '../../store-management/shared/store.model';
import * as status_message from '../shared/status_message.json'

@Component({
  selector: 'app-inbound-view-coll',
  templateUrl: './inbound-view-coll.component.html',
  styleUrls: ['./inbound-view-coll.component.scss']
})
export class InboundViewCollComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InboundViewCollComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Collection)
     { }
  colltime: string[] = ['指定なし', '午前','午後']
  status_msg = status_message.default[0]
  ngOnInit() {
  }  

  getUrg(stat: number): string{
    if(stat === 0) return '通常（至急ではない）'
    return '至急'
  }

  getCollectionStatus(head:Number,level:Number){
    for(let x = 0; x < this.status_msg["Collection"].length; x++){
      let col = this.status_msg["Collection"][x]
      if ( col['level'] == level && col['head'] == head ){
        return col['message']
      }
    }
  }

  

}
