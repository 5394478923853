import { Injectable } from '@angular/core';

import { DataService, imagesUrl } from '../../data.service';

@Injectable({
  providedIn: 'root'
})
export class ImageToolsSourceService {

  constructor(
  	private dataService: DataService) { }

  getImages(imageType: string) {
    return this.dataService.get(imagesUrl + imageType, null);
  }
}

export class Image {
  image_name: any;
  image_type: string;
  image_url: string;
}