import { resetverify } from './../../shared/data.service';
import { UploadFile } from 'ngx-file-drop';
import { InquiryService } from './../../inquiry/shared/inquiry.service';
import { Component, OnInit,ViewChild,HostListener, NgModule } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Collection } from '../shared/maintenance.model';
import { MaintenanceService } from '../shared/maintenance.service';
import { MaintenanceOptComponent } from '../maintenance-opt/maintenance-opt.component';
import { MatDialog } from '@angular/material';
import { MaintenanceData } from '../shared/maintenance.prov';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { RelationalRefData } from 'src/app/order/shared/order.prov';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-maintenance-confirm',
  templateUrl: './maintenance-confirm.component.html',
  styleUrls: ['./maintenance-confirm.component.scss'],
  providers: [ StoreService, MaintenanceService, InquiryService ]
})
export class MaintenanceConfirmComponent implements OnInit {

  public id: string;
  store: Store;
  inquiry: Collection;
  isLoading = false;
  hasData: boolean = false;
  message = '';
  type = '';
  pMsg = '';
  uFlag = false;
  maintetype = ''
  item_selected = '';
  btnDisable = false

  urls : string[] = [];


  constructor(
    public badgeService: BadgeSocketService,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private maintenanceService: MaintenanceService,
    private maintenanceData: MaintenanceData,
    public http: HttpClient,
    public dialog: MatDialog,
    private inquiryService: InquiryService,
    private relData: RelationalRefData,
  ){}

  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore(); 
    this.message = this.maintenanceData.data.message
    this.maintetype = this.maintenanceData.data.type
    this.item_selected = this.maintenanceData.data.item_selected

    switch(this.maintenanceData.data.type){
      case '1' : {
        this.type = '修理';
        break;
      }
      case '2' : {
        this.type = '交換';
        break;
      }
      case '3' : {
        this.type = '調査';
        break;
      }
      case '4' : {
        this.type = 'その他';
        break;
      }
    }
    
    for(let file of this.maintenanceData.mainFile){
      var reader = new FileReader();
      
      reader.onload = (e: any) => {
        this.urls.push(e.target.result)
      }

      reader.readAsDataURL(file.mfile);
    }
    
    /*if(this.collectionData.collection){
      if(this.collectionData.collection.length>0){
        this.pMsg = this.collectionData.inqucollectionry.detail;
      }
    }*/
    this.uFlag = false; 
  }

  getStore() {
    this.storeService.getStore(this.id).subscribe(res => {
      this.store = res.payload.store[0];
    });
  }

  chainName: string
  getChainName(){
    this.inquiryService.getChainName(this.store.chain_no)
    .subscribe(res => {
      this.chainName = res.payload.chain[0].name
    })
  }

  toComplete() {

    this.btnDisable = true

    this.isLoading = true;
    let imgs: string[] = []
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let observables = new Array();

    for(let file of this.maintenanceData.mainFile){ 
      let body = {
        'source_id': 3,
        'created_by': tmp.userid,
        'file_description': file.mess
      }
      
      observables.push(this.maintenanceService.upload(body, file.mfile).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Uploading data...')
              return

            case HttpEventType.Response:
              let res: any = event.body
              imgs.push(res.payload.upload_id)
              return
          }
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err)
          return Observable.throw(err);
        })
      ))
    }
    Observable.forkJoin(observables)
    .subscribe(
      res =>{ 

        this.btnDisable = false
        let images = JSON.stringify(imgs);
        var save_data = {
          user_id         : tmp.userid
          , store_id      : this.store['store_no']
          , company_name  : this.store['chain_name']
          , tel           : this.store['tel'] === null ? 0 : 0
          , detail        : this.message
          , status        : '0'
          , images        : images
          , maintenance_time : '0'
          , type   : this.maintetype
          , item_selected : this.maintenanceData.data.item_selected.item_id
        }  
        this.maintenanceService.storeMaintenance(save_data).subscribe(
          res => {

            this.isLoading = false; 
            let tmp: any = res
            this.maintenanceData.data = {...this.maintenanceData.data , ...{refno : tmp.body.payload.maintenanceRefNo}}
            this.relData.mainte_ref = tmp.body.payload.maintenanceRefNo

            if(this.relData.inq_ref.length == 0){
              this.notif()
              this.router.navigate(['/main/maintenance/complete/' + this.store.store_no ]);
              return
            }
            
            if(this.relData.inq_ref.length !== 0 || this.relData.mainte_ref.length !== 0 || this.relData.coll_ref.length !== 0){
         
              let tmpuser: any = JSON.parse(sessionStorage.getItem('currentUser'))
              let body = {
                'inquiry': this.relData.inq_ref,
                'order': this.relData.order_ref,
                'collection': this.relData.coll_ref,
                'maintenance': this.relData.mainte_ref,
                'created_by': tmpuser.userid,
                'id': this.relData.updID
              }   
            
              if(this.relData.updID == null){
                this.maintenanceService.saveRelation(body)
                .subscribe(res => {
                  let tmprefid: any = res
                  this.relData.updID = tmprefid.body.insertId
                  this.notif()
                  this.router.navigate(['/main/maintenance/complete/' + this.store.store_no ]);
                })
              }else{
                console.log('UPDATING RELATION')
                this.maintenanceService.updateRelation(body)
                .subscribe(res => {
                  this.notif()
                  this.router.navigate(['/main/maintenance/complete/' + this.store.store_no ]);
                })
              }
            }else{
              this.notif()
              this.router.navigate(['/main/maintenance/complete/' + this.store.store_no ]);
            }
          }
        )
      }
    )
  }

  notif(){
    let view_tmp = {
      'cc': false,
      'hd': false,
      'wh': true,
      'rr': false,
      'gg': false,
      'vr': false
    }
    //this.badgeService.pushBadgeNotif(view_tmp)
  }

  onBack(){
    this.router.navigate(['/main/maintenance/input/' + this.id]);
  }

}
