import { Component, OnInit } from '@angular/core';

import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { RouterLinkWithHref } from '@angular/router';
import { ManualService } from '../shared/manual.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  manualForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<UploadFileComponent>
    , private formBuilder: FormBuilder
    , private service: ManualService) { }

  ngOnInit() {
    this.createForm();
  }

  localUrl      = "";
  localFile     = '';
  localFilename = '';
  isUploading   = false;

  onSelectFile(event) { 
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.localUrl = event.target.result; 
        }
        reader.readAsDataURL(event.target.files[0]);
        this.localFile = event.target.files[0];
        this.localFilename = event.target.files[0].name;
      } 
  }

  submit(){
    if(this.localFile) {
      this.isUploading = true;
      let raw = this.manualForm.getRawValue(); 
      raw.source_id = 3;
      raw.created_by = 1; 
      
      this.service.fileUpload(raw, this.localFile).pipe(
      map(event => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Uploading data...')
              return

            case HttpEventType.Response: 
                this.isUploading = false;
                this.dialogRef.close(true)
              return
          }
        })
      ).subscribe() 
    }
  }

  createForm(): void {  
    this.manualForm = this.formBuilder.group({
      file_description: []
    }); 
  }

}
