import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSort, MatTableDataSource } from '@angular/material';
import { Chain_list } from '../../chain-management/shared/chain.model';
import * as status_message from '../shared/status_message.json'

export interface Item{
  item_no: string
  item_ref_no: string
  name: string
  order_item_id: number
  order_no: string
  price: number
  qty: number
  status: number

}

@Component({
  selector: 'app-inbound-view-order',
  templateUrl: './inbound-view-order.component.html',
  styleUrls: ['./inbound-view-order.component.scss']
})
export class InboundViewOrderComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['id', 'name', 'price', 'qty'];
  itemList: Item[] = []
  dataSource: MatTableDataSource<Item>;
  hoped_time = ['指定なし','午前','午後','']
  constructor(public dialogRef: MatDialogRef<InboundViewOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ) { }
  status_msg = status_message.default[0]
  ngOnInit() {
    console.log(this.data)
    this.itemList = this.data.ordered_items
    this.dataSource = new MatTableDataSource(this.itemList);
    this.dataSource.sort = this.sort;
    this.dataSource.data = this.itemList
    this.dataSource.filterPredicate = (data: Item, filters: string) =>{
      const matchFilter = [];
      const filterArray = filters.split(',')
      const columns = [ data.order_no, data.item_ref_no, data.name ]

      filterArray.forEach(filter =>{
        const customFilter = [];
        columns.forEach(column => customFilter.push(column.toLowerCase().startsWith(filter)));
        matchFilter.push(customFilter.some(Boolean));
      })
      return matchFilter.every(Boolean);
    }
   }

  getUrg(stat: number): string{
    if(stat === 0) return '通常（至急ではない）'
    return '至急'
  }

  getOrderProcessor(head:Number,level:Number,task_status:Number,task_cccancel:Number,task_whcancel:Number){
    for(let x = 0; x < this.status_msg["Order"].length; x++){
      let col = this.status_msg["Order"][x]
      if ( col['level'] == level && col['head'] == head ){
        if (task_cccancel == 1 || task_whcancel == 1) {
          return 'なし'  
        }else{
          return col['processor']
        }
      }
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getOrderStatus(head:Number,level:Number,task_status:Number){
    for(let x = 0; x < this.status_msg["Order"].length; x++){
      let col = this.status_msg["Order"][x]
      if ( col['level'] == level && col['head'] == head ){
        return col['message']
      }
    }
  }
  getOrderStatus_Cancel(task_cccancel:Number,task_whcancel:Number){
    if (task_cccancel != 0 || task_whcancel != 0) {
      for(let x = 0; x < this.status_msg["Order"].length; x++){
        let cancel = this.status_msg["Order"][x]
        if ( cancel['cc_cancel'] == task_cccancel && cancel['wh_cancel'] == task_whcancel ){  
          return cancel['message']                    
        }   
      }
    }
  }

}
