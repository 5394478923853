import { autUserhUrl, usersUrl } from './../../shared/data.service';
import { Injectable } from '@angular/core';


import { DataService, adminUsersUrl} from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isSignedIn = false;
  currentUser: any;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private dataService: DataService
    ) { }

  signin(body: any) {

    return this.dataService.post(adminUsersUrl + 'login', body);
  }

  signin2(user: string, pass: string){
    return this.dataService.userAuth(autUserhUrl, user, pass);
  }

  signout() {
    this.isSignedIn = false;
    sessionStorage.removeItem('g-retail-token');
    sessionStorage.removeItem('currentUser');
  }

  setUser(response: any) {
    let body = {
      designation: '',
      username: response.name,
      isSignedIn: true,
      usertype: response.type,
      userrole: response.role,
      userid: response.user_no,
    }
    sessionStorage.setItem('g-retail-token', response.token);
    this.currentUser = body
    sessionStorage.setItem('currentUser', JSON.stringify(body))
  }

  hasCurrentUser() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.currentUser != null) {
      this.isSignedIn = this.currentUser.isSignedIn;
      return true;
    }

    return false;
  }

  showOtherMessage(message: string) {
    this.dataService.showOtherMessage(message);
  }
}
