import { Component, OnInit, Inject } from '@angular/core';
import { Carrier_list } from '../shared/carrier.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as prefectures from '../store-opt/city_pref.json'

export interface KeyValue {
  key: any;
  value: string;
}

@Component({
  selector: 'app-carrier-view',
  templateUrl: './carrier-view.component.html',
  styleUrls: ['./carrier-view.component.scss']
})
export class CarrierViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CarrierViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Carrier_list) { }


  ngOnInit() {
  }


}
