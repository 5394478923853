import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Items } from '../shared/item.model';
import { ItemService } from '../shared/item.service';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';

import * as _moment from 'moment';
import { Item_categ_list } from '../../item-categ-management/shared/item-categ.model';
import { Item_categ_first_list } from '../../item-categ-first-management/shared/item-categ-first.model';
import { formatDate } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
//import {default as _rollupMoment} from 'moment';
const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface KeyValue {
  key: any;
  value: string;
}

@Component({
  selector: 'app-item-opt',
  templateUrl: './item-opt.component.html',
  styleUrls: ['./item-opt.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})


export class ItemOptComponent implements OnInit {

  // disabledSubmit: boolean = false
  date = new FormControl(moment());
  date2 = new FormControl(moment());
  itemForm: FormGroup;
  opt: string
  item: Items;
  showError: boolean = false;
  err_msg: string = '';
  err_code: string = '';
  categ: Item_categ_list[] = [];
  first_categ: Item_categ_first_list[] = [];
  localUrl = "";
  imgFile: File
  isUpload = false
  isLoad = false
  showLoading = false
  showUploadError = false
  showErrorText = false
  errorText = ''

  //Multiple Image upload
  myFiles:any [] = [];
  img: File[] = [];
  asImage = false 
  idFiles:any [] = [];
  imgId:any [] = [];
  listofsubimgs:any;


  catControl = new FormControl('', [Validators.required]);

  utype: KeyValue[] = [
    { key: "type1", value: "Type 1" },
    { key: "type2", value: "Type 2" },
    { key: "type3", value: "Type 3" }
  ];
  utypeControl = new FormControl('');

  avail: KeyValue[] = [
    { key: "av", value: "Disabled" },
    { key: "un", value: "Active" }
  ];
  availControl = new FormControl('');

  mnfc: KeyValue[] = [
    { key: '0', value: 'メーカー' },
    { key: '1', value: 'メーカー1' },
    { key: '2', value: 'メーカー2' },
    { key: '3', value: 'メーカー3' },
    { key: '4', value: 'メーカー4' }
  ];

  mnfcControl = new FormControl('');

  posib: KeyValue[] = [
    { key: '0', value: "可" },
    { key: '1', value: "不可" }
  ];
  posibControl = new FormControl('');

  suffix: KeyValue[] = [
    { key: '0', value: "本" },
    { key: '1', value: "枚" },
    { key: '2', value: "冊" },
    { key: '3', value: "機" },
    { key: '4', value: "個" },
    { key: '5', value: "ケ" }
  ];
  suffixControl = new FormControl('');

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 999, value: "その他" }
  ]

  constructor(
    public dialogRef: MatDialogRef<ItemOptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: ItemService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.data.option === 'edit') {
      this.opt = '商品の情報編集'
      this.isUpload = false
      this.localUrl = this.data.img
    }
    else this.opt = '商品の追加入力'
    this.categ = this.data.categs
    this.first_categ = this.data.first_categs

    this.createForm();
   
  }

  getSubImgUrls(){
    this.itemService.getSubImgUrls(this.data.sub_imgs)
    .subscribe(res => {
      this.idFiles = res.payload.upload_files
    })
  }

  createForm(): void {
    let catInd: number
    let first_catInd: number
    let tierInd: number
    console.log('data', this.data)
    if (this.data.option === 'edit') {
      for (let x = 0; x < this.categ.length; x++) {
        if (this.categ[x].cat_no === this.data.cat) catInd = x
      }

      for (let x = 0; x < this.tierLvl.length; x++) {
        if (this.tierLvl[x].key === this.data.tier) tierInd = x
      }

      for (let x = 0; x < this.first_categ.length; x++) {
        if (this.first_categ[x].first_cat_no === this.data.first_cat) first_catInd = x
      }

      this.getSubImgUrls()
      this.imgUrl = this.data.img
        
    }
    this.itemForm = this.formBuilder.group({
      cat: [this.data.option === 'edit' ? this.categ[catInd] : undefined, Validators.required],
      first_cat: [this.data.option === 'edit' ? this.first_categ[first_catInd] : undefined, Validators.required],
      possibility: [this.data.option === 'edit' ? this.posib[this.data.possibility] : ''],
      mnfc: [this.data.option === 'edit' ? this.mnfc[this.data.mnfc] : ''],
      suffix: [this.data.option === 'edit' ? this.suffix[this.data.suffix] : '', Validators.required],
      tier: [this.data.option === 'edit' ? this.tierLvl[tierInd] : '', Validators.required],
      item_no: [this.data.item_no, Validators.required],
      barcode: [this.data.barcode, Validators.required],
      name: [this.data.name, Validators.required],
      price: [this.data.price],
      availability: [this.data.option === 'edit' ? this.data.availability : 1],
      int_stock: [this.data.int_stock, Validators.required],
      booked_stock: [this.data.booked_stock, Validators.required],
      stock_type: [this.data.stock_type],
      spec1: [this.data.spec1],
      spec2: [this.data.spec2],
      spec3: [this.data.spec3],
      spec4: [this.data.spec4],
      spec5: [this.data.spec5],
      utencil_size: [this.data.utencil_size],
      pkg_size: [this.data.pkg_size],
      acc_pkg_size: [this.data.acc_pkg_size],
      min_lot: [this.data.min_lot],
      ship_lot: [this.data.ship_lot],
      remarks: [this.data.remarks],
      note: [this.data.note],
      retail: [this.data.retail],
      carrier: [this.data.carrier],
      acc: [this.data.acc],
      
    });
    if (this.data.option === 'edit') {
      this.date.setValue(new Date(formatDate(this.data.started_date, 'longDate', 'en-US')))
      this.date2.setValue(new Date(formatDate(this.data.exp_date, 'longDate', 'en-US')))
    }

  }
  imgUrl ="";
  onSubmit() {
    console.log("IMGID ITO-" , this.imgId,this.idFiles)
    if (this.imgUrl.length === 0 && this.data.option === 'edit') this.imgUrl = this.data.icon

    if(this.data.option === 'edit'){
      for (let x = 0; x <= this.idFiles.length -1; x++) {
        console.log("for imgID remaining",)
        this.imgId.push(this.idFiles[x].id)
      }
    }
    // this.disabledSubmit = true;

    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let val: any = this.itemForm.getRawValue()
    let started_date = formatDate(new Date(this.date.value), 'yyyy-MM-dd', 'en-US')
    let exp_date = formatDate(new Date(this.date2.value), 'yyyy-MM-dd', 'en-US')

    let body: any;

    if (this.data.option === 'add') {
      body = {
        'item_no': val.item_no,
        'barcode': val.barcode,
        'name': val.name,
        'spec1': val.spec1,
        'spec2': val.spec2,
        'spec3': val.spec3,
        'spec4': val.spec4,
        'spec5': val.spec5,
        'price': val.price != '' ? val.price : 0,
        'int_stock': val.int_stock,
        'availability': val.availability,
        'booked_stock': val.booked_stock,
        'stock_type': val.stock_type != '' ? val.stock_type : 0,
        'img': this.imgUrl,
        'utencil_size': val.utencil_size != '' ? val.utencil_size : 0,
        'pkg_size': val.pkg_size != '' ? val.pkg_size : 0,
        'acc_pkg_size': val.acc_pkg_size != '' ? val.acc_pkg_size : 0,
        'tier': val.tier.key,
        'retail': val.retail != '' ? val.retail : 0,
        'carrier': val.carrier != '' ? val.carrier : 0,
        'cat': Number(val.cat.cat_no),
        'first_cat': Number(val.first_cat.first_cat_no),
        'acc': val.acc != '' ? val.acc : 0,
        'remarks': val.remarks != '' ? val.remarks : 0,
        'min_lot': val.min_lot,
        'suffix': Number(val.suffix.key),
        'ship_lot': val.ship_lot != '' ? val.ship_lot : 0,
        'possibility': val.possibility.key != undefined ? val.possibility.key : 0,
        'mnfc': val.mnfc.key != undefined ? val.mnfc.key : 0,
        'started_date': '2018-10-01',
        'exp_date': '2018-10-01',
        'note': val.note != null ? val.note : ' ',
        'status': 9,
        'created_by': tmp.userid,
        'sub_imgs':   "("+ this.imgId.toString()+")" ,    
      }
    } else if (this.data.option === 'edit') {
      body = {
        'item_id': this.data.item_id,
        'item_no': val.item_no,
        'barcode': val.barcode,
        'name': val.name,
        'spec1': val.spec1,
        'spec2': val.spec2,
        'spec3': val.spec3,
        'spec4': val.spec4,
        'spec5': val.spec5,
        'price': val.price,
        'availability': val.availability,
        'int_stock': val.int_stock,
        'booked_stock': val.booked_stock,
        'stock_type': val.stock_type,
        'img': this.imgUrl,
        'utencil_size': val.utencil_size,
        'pkg_size': val.pkg_size,
        'acc_pkg_size': val.acc_pkg_size,
        'tier': val.tier.key,
        'retail': val.retail,
        'carrier': val.carrier,
        'cat': val.cat.cat_no,
        'first_cat': val.first_cat.first_cat_no,
        'acc': val.acc,
        'remarks': val.remarks,
        'min_lot': val.min_lot,
        'suffix': val.suffix.key,
        'ship_lot': val.ship_lot,
        'possibility': val.possibility.key,
        'mnfc': val.mnfc.key,
        'started_date': started_date,
        'exp_date': exp_date,
        'note': val.note,
        //'status' : 0,
        'updated_by': tmp.userid,
        'sub_imgs':  "("+ this.imgId.toString()+")" ,
      }
    }

    this.itemService.saveItem(body, this.data.option)
      .subscribe(res => {
        let ttmp: any = res
        console.log('saving data from item', res, this.img.length )
        if (ttmp.body.success) {
          this.dialogRef.close(true)
          console.log("success! ->" ,this.imgId.toString() )       
        } else {
          this.showLoading = false
          this.showUploadError = false
          this.showErrorText = true
          this.errorText = ttmp.body.message   
        }
      })
    this.itemService.getItems('', '0' , '50000');

    //proceed to upload multile sub_imgs
    
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.imgFile = event.target.files[0];
      this.isUpload = true
    }
  }

  
  getFileDetails(event) { 
    for (var i = 0; i < event.target.files.length; i++) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.myFiles.push(event.target.result);
      }
      reader.readAsDataURL(event.target.files[i]);
      this.img.push(event.target.files[i])
      this.asImage = true;
      // this.isUpload = true
    }
  }

  remove(element:any){
    this.idFiles.splice(element,1)
    this.imgId.splice(element,1);
    console.log("idFiles",this.imgId,this.idFiles, element )
    //this.imgId= [] 
    //for (let x = 0; x <= this.idFiles.length -1; x++) {
    //  console.log("for imgID remaining",)
    //  this.imgId.push(this.idFiles[x].id)
    //}

  }

  removeId(element:any){
    this.myFiles.splice(element,1)
  }
  

  sub_imgsUpload() {
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body = {
      'source_id': 2,
      'created_by': tmp.userid,
      'file_description': 'none'
    }
    console.log("COUNT OF IMG TO UPLOAD -> ", this.img.length  )
    for (let x = 0; x <= this.img.length -1; x++) {
      this.itemService.upload(body,this.img[x]).pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.Sent:
              console.log('Uploading data...')
              return

            case HttpEventType.Response:
              //this.isLoad = true
              let res: any = event.body
              this.listofsubimgs = JSON.stringify(res.payload.upload_id)//.replace('[', '').replace(']','') 
              this.imgId.push(this.listofsubimgs) 
              if(x >= (this.img.length -1) ){
                //console.log("itemlogs -> ", this.imgId ) 
                this.onSubmit()
              }
              return 
              
          }
        }),
        catchError((err: HttpErrorResponse) => {
          console.log(err)
          this.showLoading = false
          this.showUploadError = true
          this.showErrorText = false
          return Observable.throw(err);
        })
      ).subscribe()
    }

  }

  imgUpload() {
    console.log(this.imgFile, this.img) 
   
    this.isLoad = true
    if (this.itemForm.invalid) {
      this.showLoading = false
      this.showUploadError = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    };

    this.showLoading = true
    this.showUploadError = false
    this.showErrorText = false

    if (!this.isUpload && this.img.length == 0 ) {
      this.onSubmit()
      return
    }

    if (!this.isUpload && this.img.length != 0 ) {
      this.sub_imgsUpload()
      //this.onSubmit() 
      return
    }

    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let body = {
      'source_id': 2,
      'created_by': tmp.userid,
      'file_description': 'none'
    }

    this.itemService.upload(body, this.imgFile).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Uploading data...')
            return

          case HttpEventType.Response:
            this.isLoad = true
            let res: any = event.body
            console.log(res);

            if(this.img.length != 0 ){
              this.sub_imgsUpload()
              this.imgUrl = res.payload.filename
            }
            else if (this.img.length == 0) {
              this.imgUrl = res.payload.filename
              this.onSubmit()
            }
           
            return
        }
      }),
      catchError((err: HttpErrorResponse) => {
        console.log(err)
        this.showLoading = false
        this.showUploadError = true
        this.showErrorText = false
        return Observable.throw(err);
      })
    ).subscribe()
  }

  cancelSave() {
    this.isLoad = false
    this.showLoading = false
    this.showUploadError = false
    this.showErrorText = false
  }

  saveWithNoImage() {
    this.isLoad = true
    this.showLoading = true
    this.showUploadError = false
    this.showErrorText = false
    this.onSubmit()
  }
}
