// DEMO KEYS STG
const tablesTypes: any = [
  {
    table_name: 'campaign',
    spreadsheet_key: '1LeccxzyX-n9aPQ70Ca5JkIDoy_qNaU3Zx3utThKuYwE'
  },
  {
    table_name: 'fixture_info',
    spreadsheet_key: '1F_PUw5cLONXmynd0gYgT6wYzY2y535AztZ86pLi_-VI',
  },
  {
    table_name: 'fixture_mapping',
    spreadsheet_key: '1Npr8jlzfb6-tjeHSY2G32cZ4E8PR7J10RBHUxnnOklY',
  },
  {
    table_name: 'forecast_living_room',
    spreadsheet_key: '1jGUFjY9Mo_s2PWIh26PbDUbV62IWRX9_wERqICJxICw',
  },
  {
    table_name: 'model_info',
    spreadsheet_key: '1fT_ykpXVbUNBcKayZ89jFwjRqAjFvVsAa3Za_KWdBG8',
  },
  {
    table_name: 'model_info_mobility',
    spreadsheet_key: '1n26y5ZaPRo4E--D-c8s_4FNtlX0H9l0l_w4FoGNM55k', //Change this key for demo
  },
  {
    table_name: 'product_info',
    spreadsheet_key: '1k1VdOw6IDluEFgylIEahKbfRPDGwyo4uNWPagzMrPcI',
  },
  {
    table_name: 'promoter_setting',
    spreadsheet_key: '1R-ZZuDeCE2bpQYb8eae_-rLhx8t5f3Y1sC-5Wmaww70',
  },
  {
    table_name: 'promoter_unit_price',
    spreadsheet_key: '1Ja2NtaJtt-HyxeC1MAxhTBkYOShbhz_Pmp1bjKVWtPk',
  },
  {
    table_name: 'sellout_living_room',
    spreadsheet_key: '1eCnx9kOhOdDynYbQvxETFlkzbvzZ_YkublFgcDpoIQk',
  },
  {
    table_name: 'sellout_mobility',
    spreadsheet_key: '1ud2fxFQNqueN2UAPP-8TqIYmyMEnTMmBj2_bqp6N33o',
  },
  {
    table_name: 'tsm_cover_list',
    spreadsheet_key: '10KDuQ7nt2ObvYo011f13uphM3OZCI4n5hL2mw_-FLZ8',
  },
  {
    table_name: 'tsm_record',
    spreadsheet_key: '1Knixco9lHp2_ekajFV-K9x0sH1t9gNkzTW7fxob94IE',
  },
  {
    table_name: 'tsm_setting',
    spreadsheet_key: '14dosFdtboxMsW0KHSUcQn-6Vn8iK9vSs17PLUNUHx-o',
  },
  {
    table_name: 'tsm_unit_price',
    spreadsheet_key: '1O5GJOhJG_tgJmXomSVICBCvKRwUKjw3S8soN2fcH9F4',
  },
  {
    table_name: 'tsm_user_list',
    spreadsheet_key: '1ZS_9hCv0fRfjf2O2poBOoRXruqjrayKe-ZINw-pg5Ys',
  },
  {
    table_name: 'week_management',
    spreadsheet_key: '1KR0PKL04eh_XjPXsDXi76ZJhkUMXXlGy74o1nAUdjZE',
  },
  {
    table_name: 'target_living_room',
    spreadsheet_key: '1pT_LLrj_pWSzL9XxlYf-113XJpe_PmyasSwSYSX1GAI',
  },
  {
    table_name: 'target_mobility',
    spreadsheet_key: '1btSKHqFDNHbM-46tiDh5D52YMSpoRyu7-KK-dtSwz6U',
  },
  {
    table_name: 'forecast_mobility',
    spreadsheet_key: '11Hv83Aa2IPtRPUgGhR2JAEMJxZyV_0_o81SgZ8BjYfA',
  },
  {
    table_name: 'store_master',
    spreadsheet_key: '1THMh-Gx2tiWN3r48azzS22sgZ_EZ0c0YdUX_Ic7cziY',
  },
  {
    table_name: 'carrier_master',
    spreadsheet_key: '1t0IH2ZE04_sAL7bj9TVWdExNV3Amp6CmwqYhUnHlbxc',
  }
];


// DEV_TEST_2020 spreadsheets
// const tablesTypes: any = [
//     {
//       table_name: 'campaign',
//       spreadsheet_key: '1XCQU8xSPgz1nykMnbxasb0ZY69Y8pnGPLT3cuKqo3M8'
//     },
//     {
//       table_name: 'fixture_info',
//       spreadsheet_key: '1K7scl-Qgslc88WinUu62duOtVuSj2KcmcHW0WbEW-Kg',
//     },
//     {
//       table_name: 'fixture_mapping',
//       spreadsheet_key: '1s4P6JxZZ_ftlnAa7Rqw3XM_YbllCfeXYQCvoXGXVsEE',
//     },
//     {
//       table_name: 'forecast_living_room',
//       spreadsheet_key: '16CF97PnGL_A525psKHiO4MwLLqgWa3d1CJ6RxrweOSc',
//     },
//     {
//       table_name: 'model_info',
//       spreadsheet_key: '1IWBQ6pdMxNK6PI6Kn9GGFHQOcG7_tkFHZzRYjRlUxpc',
//     },
//     {
//       table_name: 'model_info_mobility',
//       spreadsheet_key: '1grAuhj2lADjIi2DLmd01e7_7YrWrD4R2prlJCsj1q18',
//     },
//     {
//       table_name: 'product_info',
//       spreadsheet_key: '1GMhBJYqF_ulBQqeKs90skqlxoz9nsANxXMw-dEMJVy0',
//     },
//     {
//       table_name: 'promoter_setting',
//       spreadsheet_key: '1RlYUsjPtMtf4s9-A5NoIfFL7hLxvz05I7N3PqHyD1pU',
//     },
//     {
//       table_name: 'promoter_unit_price',
//       spreadsheet_key: '1CCI0HN8c2bM1BGSGfMMWq6ff_XEttLoi_82ahKbduIQ',
//     },
//     {
//       table_name: 'sellout_living_room',
//       spreadsheet_key: '1dRD52A-HJU3ndzd_afHUhy1I7CH4zU1FzbY9xj9tWSs',
//     },
//     {
//       table_name: 'sellout_mobility',
//       spreadsheet_key: '1ALq0Oohk3VHhjBIRZGFApk7GKkUdSAft0641DVtP3P8',
//     },
//     {
//       table_name: 'tsm_cover_list',
//       spreadsheet_key: '1rYbchnnAvfc88YEe_erSOCTWEdvc6HjVLmIHRxytycQ',
//     },
//     {
//       table_name: 'tsm_record',
//       spreadsheet_key: '1UNh8rroOSKxHdGjmfL4DQgpT4_-5iQl_CNTCdEOGIk4',
//     },
//     {
//       table_name: 'tsm_setting',
//       spreadsheet_key: '1nyar-ARSX_o419RHzno5rvvPNFlAXkWmWkabh_XjZWI',
//     },
//     {
//       table_name: 'tsm_unit_price',
//       spreadsheet_key: '1nus5fqgGUrnnu1mkUj4XI3qUcobEOZHEz2pUMw7N9cw',
//     },
//     {
//       table_name: 'tsm_user_list',
//       spreadsheet_key: '1zv4_IFIzLr54vx75rBHDG4DdwpuXXYvyJiom1IYB8pI',
//     },
//     {
//       table_name: 'week_management',
//       spreadsheet_key: '1RL5ADr5OuvvbDsU16j6W1DNm1zd-iv_FmVwAKSWHPF0',
//     },
//     {
//       table_name: 'target_living_room',
//       spreadsheet_key: '1Ik6TQ5qZr6KsjvkvG9pzOhcyontK5rYg_qLYh_ZNh7Q',
//     },
//     {
//       table_name: 'target_mobility',
//       spreadsheet_key: '1zJbIr8MtzIkyGDfu-d9IlsHo6ePZU_1Re3DDBjVlDL0',
//     },
//     {
//       table_name: 'forecast_mobility',
//       spreadsheet_key: '1PFrbb00iopJ8aMBbOdhycvYsoLb6nn5IjL7hSE_GVnw',
//     },
//     {
//       table_name: 'store_master',
//       spreadsheet_key: '1cNhPZaq_GEPznA7PgvbSoDSkdi7DcV9K9j2GDOttI7g',
//     },
//     {
//       table_name: 'carrier_master',
//       spreadsheet_key: '1axYtAqD1vO-A34K2lk_ScOhVuE7epWUtzOnfXjFPOoo',
//     }
//   ];


// PRODUCTION spreadsheets
/*const tablesTypes: any = [
  {
    table_name: 'campaign',
    spreadsheet_key: '1x5DPLUgjw-JOKBlUeiBSToP0Oit-kNa28FLuG2bYMiA'
  },
  {
    table_name: 'fixture_info',
    spreadsheet_key: '14qhXUSbTa27Vu4bCoPmzp-CZNn0gX_Nv09u1XAgJ0NQ',
  },
  {
    table_name: 'fixture_mapping',
    spreadsheet_key: '16s9CAqHYgimajAoeAZM3iWdEXlQojTDpIKjCNmXvITU',
  },
  {
    table_name: 'forecast_living_room',
    spreadsheet_key: '1PK5U3wERtsMAsFJzcFI0uatb4t6opIRZpTY5tKRhiWs',
  },
  {
    table_name: 'model_info',
    spreadsheet_key: '1H9iWx7U9Ax1o2ixtjiMZnrkmTAc3vRxa90OTePKuuEw',
  },
  {
    table_name: 'model_info_mobility',
    spreadsheet_key: '13cpJsCjmXZRrlV7izfSFsCpxIuKd4fmi7KH4cMelU6Q',
  },
  {
    table_name: 'product_info',
    spreadsheet_key: '1wCyVJe9wsp1BZJuMXNRc1KHe74vPQ8fV1xE_AAsMZm0',
  },
  {
    table_name: 'promoter_setting',
    spreadsheet_key: '1RdSJ-tRVGp1YRW7LyYaBvGOpy0G13GoQGMRJHaq_8Kc',
  },
  {
    table_name: 'promoter_unit_price',
    spreadsheet_key: '1AtyrCUZl79LFI3hg3orRf91AGuIUfsjbEp2TOTd1p5c',
  },
  {
    table_name: 'sellout_living_room',
    spreadsheet_key: '13D_EegyY4o6Wo-T9NRaIm2mQCC4t6tAKfp1JRJIdbBg',
  },
  {
    table_name: 'sellout_mobility',
    spreadsheet_key: '1EiPYiCAKAwWNuJ_pvSMS461A-7EdgypeF42yEdlYDnc',
  },
  {
    table_name: 'tsm_cover_list',
    spreadsheet_key: '1iJ3C5FZCaqN8QHVI7F5B6ylhH8EW36xabCl3irpDrJw',
  },
  {
    table_name: 'tsm_record',
    spreadsheet_key: '1flY69ypRX2hQwQ1RBin07G4p5HRdsrlzN7Xwj7Lj2TE',
  },
  {
    table_name: 'tsm_setting',
    spreadsheet_key: '1FzKOGm1K1f5qlupdDZ_W788UIMRoUE6y94cCv8RUhyE',
  },
  {
    table_name: 'tsm_unit_price',
    spreadsheet_key: '1i9Dv0n_65F6W0zGxv7JB98LjdEpvDiC_MNRLgoyajt0',
  },
  {
    table_name: 'tsm_user_list',
    spreadsheet_key: '1m6kWqLPIExW1SLLqECvtG49EFPP9D3kPPSdRcfUqAGk',
  },
  {
    table_name: 'week_management',
    spreadsheet_key: '1DVvNn-jKCHGmaKvEH6fVjvjDHVYGFUT9S1ebLFIQB0g',
  },
  {
    table_name: 'target_living_room',
    spreadsheet_key: '1ifhELD4esanE4b3nQdMPJMEs1LKdJcX3mCxYJto6BcY',
  },
  {
    table_name: 'target_mobility',
    spreadsheet_key: '133K4nnhCPXxtu78_oitbCJictLSWHEv3TXBk0e-9e3g',
  },
  {
    table_name: 'forecast_mobility',
    spreadsheet_key: '1AGjkj3L817hYyYqbtDpxo9BAwrt2d7XPs22HtfbeNZY',
  },
  {
    table_name: 'store_master',
    spreadsheet_key: '123CzFrRL3w5O9k_YGUjS7V4EGuluPm7rpNR6QocO5c4',
  },
  {
    table_name: 'carrier_master',
    spreadsheet_key: '1-dEzr68anK7LZhMMSx6omwYQZGi4MvJxQnT61j9684c',
  }
];
*/
export default tablesTypes;
