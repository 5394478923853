import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inquiries } from '../ordered-item.model';
import { DashboardService } from '../dashboard.service';
import { InquiryService } from '../../../inquiry/shared/inquiry.service';


@Component({
  selector: 'app-inq-confirm',
  templateUrl: './inq-confirm.component.html',
  styleUrls: ['./inq-confirm.component.scss']
})
export class InqConfirmComponent implements OnInit {

  tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
  hasData: false;

  constructor(
    public dialogRef: MatDialogRef<InqConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DashboardService,
    private inqService: InquiryService,
    ) { }

  ngOnInit() {
    console.log('ngOnInit', this.data)
    console.log(this.tmp);
  }

 
  changeStatus() {
    let body = {
      'head': 1,
      'reference': this.data.mdata.inq_ref_no,
      'type' : 0,
      'user_type': this.data.mdata.esc_direction,
      'user': this.tmp.userid,
      'level': 2,
      'status': 0,
      'user_id': this.data.mdata.created_by,
      'cc_view': this.data.type === 'cc' ? 2 : 0,
      'hd_view': this.data.type === 'hd' ? 2 : 0,
      'wh_view': this.data.type === 'wh' ? 2 : 0, 
      'rr_view': this.data.type === 'rr' ? 2 : 0, 
    }

    this.service.saveTask(body)
    .subscribe(ret => {
      console.log('saveTask', ret)
      this.dialogRef.close(true) 
    })
  }

  updateDetail(detail: string){
    let body = {
      'added_by' : this.tmp.userid,
      'details' : detail,
      'ref_no' : this.data.mdata.inq_ref_no
    }
    this.service.updateDetail(body)
    .subscribe(res => {
      this.dialogRef.close(true) 
    })
  }
}
