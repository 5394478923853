import { Injectable } from '@angular/core';
import { DataService, maintenanceUrl, fileUploadUrl, saveRelation,getItemsForMaintenance  }  from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})

export class MaintenanceService {
  
  constructor(private dataService: DataService) { }
  

  storeMaintenance(value:any) {   
    return this.dataService.storeMaintenance(maintenanceUrl, value);
  }

  upload(body: any, files: File){
    return this.dataService.fileUpload(fileUploadUrl, body, files)
  }

  saveRelation(body: any){
    return this.dataService.postRelation(saveRelation, body)
  }

  updateRelation(body: any){
    return this.dataService.putRelation(saveRelation, body)
  }

  getItems(){
    return this.dataService.get(getItemsForMaintenance, null);
  } 

}