import { Component, OnInit,ViewChild, OnDestroy,Input,HostListener } from '@angular/core';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { Order } from '../shared/order.model';
import { OrderService } from '../shared/order.service';
import { OrderData,RelationalRefData } from '../shared/order.prov';
import { OnBeforeunload } from '../shared/order.guard';
import { EditOrder } from 'src/app/shared/edit-order.prov';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss'],
  providers: [ StoreService,OrderService ]
})
export class OrderCompleteComponent implements OnInit, OnDestroy {

  urgencyFlag: boolean = false;
  order: Order[] = [];
  store: Store[] = [];
  id: string; //for store ID
  hasCart: boolean = true;
  rel:string =  '';
  isClearRefs = true;

  orderRefCode: string = "";


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private orderService: OrderService,
    private dialog: MatDialog,
    private orderData: OrderData,
    private refData:RelationalRefData,
    public editOrder: EditOrder
  ) { }

  ngOnInit() {
    this.orderRefCode = this.orderData.ref_code;
    this.id = this.route.snapshot.paramMap.get('id');
    this.store = this.orderData.store;
    this.order = this.orderData.order;
    this.rel = this.refData.inq_ref;
    this.urgencyFlag = this.orderData.flag;

    if(this.orderData.order){
      this.hasCart = true;
    }
    console.log('OrderCompleteComponent', this.refData)
  }

  getSuffix(key:number){
    key.toString();
    return this.orderService.sufList[key].value;
  }

  ngOnDestroy() {
    this.orderData.order = [];
    if(this.isClearRefs){
      this.refData.inq_ref = ''
      this.refData.order_ref = ''
      this.refData.coll_ref = ''
      this.refData.mainte_ref = ''
      this.refData.updID = null
    }
  }

  toCollection(){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/collection/input/'+this.id]);
  }

  toMaintenance(){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/maintenance/input/'+this.id]);
  }
}
