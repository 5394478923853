import { fileUploadUrl } from './../../shared/data.service';
import { Injectable } from '@angular/core';
import { DataService, addItemUrl,delItemUrl,itemsUrl,categoriesUrl, first_categoriesUrl, itemPaginationUrl, multipleStockUpdate,uploadFilestUrl } from '../../shared/data.service';

import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpEventType ,HttpParams, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  enableLog: boolean;
  public log(message: any) { if (this.enableLog) console.log(message); }
  constructor(private dataService: DataService,public http: HttpClient) { }

  sufList = [
    { "value":"本" },
    { "value":"枚" },
    { "value":"冊" },
    { "value":"機" },
    { "value":"個" },
    { "value":"ケ" }
  ];
  
  getCategories() {
    return this.dataService.get(categoriesUrl, null);
  }

  getFirstCategories() {
    return this.dataService.get(first_categoriesUrl, null);
  }

  getItems(msearch: string, offset: string, limit: string){
    return this.dataService.get(itemsUrl + '?mseach=' + msearch + '&offset=' + offset + '&limit=' + limit, null);
  }
  getSubImgUrls(sub_imgs:string){
    return this.dataService.get(uploadFilestUrl +"/findById_IN/"+sub_imgs,null);
  }

  saveItem(body:any, opt :string){
    if(opt === 'delete')
      return this.itemOpt(delItemUrl, body, opt)
    else
      return this.itemOpt(addItemUrl, body, opt)
  }

  itemPagination(body : any){
    return this.dataService.post(itemPaginationUrl, body);
  }

  //item management
  itemOpt(url: string, body: any,opt: string){ 
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let params: any
    if(opt === 'add'){
      params = new HttpParams()
        .set('item_no', body.item_no)
        .set('barcode', body.barcode)
        .set('name', body.name)
        .set('spec1', body.spec1)
        .set('spec2', body.spec2)
        .set('spec3', body.spec3)
        .set('spec4', body.spec4)
        .set('spec5', body.spec5)
        .set('price', body.price)
        .set('availability', body.availability)
        .set('int_stock', body.int_stock)
        .set('booked_stock', body.booked_stock)
        .set('stock_type', body.stock_type)
        .set('img' , body.img)
        .set('utencil_size' , body.utencil_size)
        .set('pkg_size', body.pkg_size)
        .set('acc_pkg_size', body.acc_pkg_size)
        .set('tier' , body.tier)
        .set('retail', body.retail)
        .set('carrier', body.carrier)
        .set('cat', body.cat)
        .set('first_cat', body.first_cat)
        .set('acc', body.acc)
        .set('remarks', body.remarks)
        .set('min_lot', body.min_lot)
        .set('suffix', body.suffix)
        .set('ship_lot', body.ship_lot)
        .set('possibility', body.possibility)
        .set('mnfc', body.mnfc)
        .set('started_date', '2018-10-01')
        .set('exp_date', '2018-10-30')
        .set('note', body.note)
        .set('status' , body.status)
        .set('sub_imgs', body.sub_imgs)
        .set('created_by',body.created_by);

        console.log('save item')
        console.log(body)

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'edit'){

      params = new HttpParams()
        .set('item_no', body.item_no)
        .set('barcode', body.barcode)
        .set('name', body.name)
        .set('spec1', body.spec1)
        .set('spec2', body.spec2)
        .set('spec3', body.spec3)
        .set('spec4', body.spec4)
        .set('spec5', body.spec5)
        .set('price', body.price)
        .set('availability', body.availability)
        .set('int_stock', body.int_stock)
        .set('booked_stock', body.booked_stock)
        .set('stock_type', body.stock_type)
        .set('img' , body.img)
        .set('utencil_size' , body.utencil_size)
        .set('pkg_size', body.pkg_size)
        .set('acc_pkg_size', body.acc_pkg_size)
        .set('tier' , body.tier)
        .set('retail', body.retail)
        .set('carrier', body.carrier)
        .set('cat', body.cat)
        .set('first_cat', body.first_cat)
        .set('acc', body.acc)
        .set('remarks', body.remarks)
        .set('min_lot', body.min_lot)
        .set('suffix', body.suffix)
        .set('ship_lot', body.ship_lot)
        .set('possibility', body.possibility)
        .set('mnfc', body.mnfc)
        .set('started_date', '2018-10-01')
        .set('exp_date', '2018-10-30')
        .set('note', body.note)
        //.set('status' , body.status)
        .set('updated_by', body.updated_by)
        .set('sub_imgs', body.sub_imgs);
      console.log(url)
      return this.http.put(url + '/' + body.item_id, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
    else if(opt === 'delete'){
      console.log('BODY :: ' + JSON.stringify(body))
      params = new HttpParams()
        .set('item_id', body.item_id)
        .set('deleted_by', body.deleted_by);

      return this.http.post(url, params.toString(), { headers, observe: 'response' })
        .pipe(tap((res: HttpResponse<any>) => this.log('fetched data')))
        .catch((err: any) => Observable.of(false));
    }
  }

  upload(body: any, files: File){
    return this.dataService.fileUpload(fileUploadUrl, body, files)
  }

  saveStock(body: any){
    return this.dataService.post(multipleStockUpdate, body);
  }
  
}
