import { Injectable } from '@angular/core';

@Injectable()

export class CollectionData {
    public store: any;
    public store_id: any;
    public remarks: any;
    public address: any;
    public shop_incharge: any;
    public shop_date_hopes: any;
    public urg_flg: any;
    public ref_code: string;
    public phone_number: string
    public constructor() { }
}
