import { Injectable } from '@angular/core';
import { DataService, emailSentUrl, passUpdateUrl, resetverify } from '../../../shared/data.service';

@Injectable({
  providedIn: 'root'
})

export class ResetPasswordService {

  constructor( private dataService: DataService ) { }

  //for sanitizing

  showErrorFillMessage() {
    this.dataService.showErrorFillMessage();
  }
  
  showAtleastMessage(){
    this.dataService.showAtleastMessage();
  }

  showDiffMessage(){
    this.dataService.showDiffMessage();
  }

  //sending email for the reset password request
  sentEmail(body: any) {
    //this.isSent = false; //can Send Email;
    console.log(body);
    return this.dataService.post(emailSentUrl, body);
  }

  updatePassword(body: any) {
    return this.dataService.post(passUpdateUrl, body);
  }

  verifyToken(url : string){
    return this.dataService.get(resetverify + url, null);
  }
}
