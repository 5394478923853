import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { first_categoriesUrl, first_addCategoryUrl, first_delCategoryUrl } from './../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class ItemCategFirstService {

  constructor(private dataService: DataService) { }

  getFirstCategList(){
    return this.dataService.get(first_categoriesUrl, null);
  }
  saveCateg(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.first_categoryOpt(first_delCategoryUrl, body, opt)
    else
      return this.dataService.first_categoryOpt(first_addCategoryUrl, body, opt)
  }
}
