import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vender-management',
  templateUrl: './vender-management.component.html',
  styleUrls: ['./vender-management.component.scss']
})
export class VenderManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
