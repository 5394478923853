import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Items } from '../../item-management/shared/item.model';
import { CarrierThirdCatlist } from '../../carrier-third-cat-management/shared/carrier-third-cat.model';

@Component({
  selector: 'app-carrier-third-cat-view',
  templateUrl: './carrier-third-cat-view.component.html',
  styleUrls: ['./carrier-third-cat-view.component.scss']
})
export class CarrierThirdCatViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CarrierThirdCatViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CarrierThirdCatlist) { }

  ngOnInit() {
  }

}
