import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ResetPasswordService } from './shared/reset-password.service'; 
import { Response } from 'selenium-webdriver/http';
import { MatDialog, MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  logo: string = "G-Retail";
  title: string = 'パスワードをリセットする';
  subtitle: string = 'ログインＩＤ(メールアドレス)を入力してください';
  resetForm: FormGroup;
  hide: boolean = true; 
  loading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public snackBar: MatSnackBar,
    private resetpasswordService: ResetPasswordService,
  ) { 

    this.resetForm = new FormGroup({
      resetForm: new FormControl('', Validators.required)
  })
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.resetForm = this.formBuilder.group({
      mail_address: [ '', ( Validators.required,Validators.email ) ],
      reset_date :  this.formatDate()
    });
  }

  formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

  onSubmit() {
    
    if(this.resetForm.invalid){
      this.resetpasswordService.showErrorFillMessage();
      return;
    } else {
      //sending Email 
      this.loading = true;
      this.resetpasswordService.sentEmail(this.resetForm.getRawValue()).subscribe((res: Response) => {
        let response : any = res;
          console.log(response);
          if(response.success){
            this.loading = false;
            this.router.navigate(['reset-password/sent-email']);
          } else if(response.error == 'reset more than 5 times today') {
            this.snackBar.open(`リセットパスワードが制限を超えています。
            明日もう一度お試しください。`, null, {
              duration: 2000,
            });
            this.loading = false;
            return false
          }
        }, error => {
          this.loading = false;
          console.log('failure:'+error);
          //this.resetpasswordService.showNotExisitingMessage(); have to create hereafter
      });
    }
  }
}