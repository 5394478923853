import { Chain_list } from './../shared/chain.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-chain-view',
  templateUrl: './chain-view.component.html',
  styleUrls: ['./chain-view.component.scss']
})
export class ChainViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChainViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Chain_list) { }

  ngOnInit() {
  }

}
