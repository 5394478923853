export class ExportUtility {

    public static downloadcsv(data: any, exportFileName: string) {
        let csvData = this.convertToCSV(data);
        const encoding = require('encoding-japanese');
        const str_array = encoding.stringToCode(csvData);
        const sjis_array = encoding.convert(str_array, "SJIS", "UNICODE");
        const uint8_array = new Uint8Array(sjis_array);
        let blob = new Blob([uint8_array], { type: "text/csv;charset=Shift-JIS;" });  

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, this.createFileName(exportFileName))
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", this.createFileName(exportFileName));
                //link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    private static convertToCSV(objarray: any) {
        let array = typeof objarray != 'object' ? JSON.parse(objarray) : objarray;
        let str = '';
        let row = "";

        for (let index in objarray[0]) {
            //Now convert each value to string and comma-separated
            row += index + ',';
        }
        row = row.slice(0, -1);
        //append Label row with line break
        str += row + '\r\n';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += JSON.stringify(array[i][index]);
            }
            str += line + '\r\n';
        }
        return str;
    }

    private static createFileName(exportFileName: string): string {
        let date = new Date();
        return (exportFileName + date.toLocaleDateString() + "_" + date.toLocaleTimeString() + '.csv')
    }

    public static transform(value: any, filter: any, price: any): any {
        if (value == null) { return '' }
        switch (filter) {
            case 'text':
                return value;
            case 'compute':
                return price;
            case 'date':
                return new Date(value).toLocaleDateString();
            default:
                return value;
        }
    }

}