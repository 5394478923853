import { venderUrl } from './../shared/data.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UploadTableCsvService } from './shared/upload-table-csv.service';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { ExportToCsv } from 'export-to-csv';
import { ExportUtility } from './../dashboard/shared/order-confirm/export-utility';
import { variable } from '@angular/compiler/src/output/output_ast';
import { SuccessDialogComponent } from './../shared/success-dialog/success-dialog.component';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import tablesTypes from './tables_types';

// import * as detectCharacterEncoding from '../../../node_modules/detect-character-encoding';
// import * as fs from '../../../node_modules/fs-extra';

@Component({
  selector: 'app-upload-table-csv',
  templateUrl: './upload-table-csv.component.html',
  styleUrls: ['./upload-table-csv.component.scss']
})
export class UploadTableCsvComponent implements OnInit {

	hasSelected: boolean = false;
	hasSelectedFile: boolean = false;
	validateCSVSize: boolean;
	validateCSV: boolean;
	isLoading: boolean = false;
	// database value equivalent
	// #1 - fixture_info_data
	// #2 - fixture_mapping_data
	// #3 - tsm_user_list_data
	// #4 - tsm_cover_list_data

	// tslint:disable-next-line: indent
	tables_type: any = [
    "fixture_info_data"
    , "fixture_mapping_data"
    , "tsm_user_list_data"
    , "tsm_cover_list_data"
  ]

  tables_types = tablesTypes



	selectedTableType: any;
	selectedTableTypeKey: any;
	@ViewChild('input_csvFile') csvFileRef: ElementRef;
	@ViewChild('input_tableType') selectTableTypRef: ElementRef;
  csvFile: File;
	localUrl: any;
	records: any;
	fields: any;
	encodingResult: any;

  constructor(
    private service: UploadTableCsvService,
    private formBuilder: FormBuilder,
		private dialog: MatDialog
    ) { }


  ngOnInit() {
		// console.log(detectCharacterEncoding);
		// console.log(fs);
		console.log(this.tables_types);
  }
	selectedTable(event : any){
		this.hasSelected = true;
		this.selectedTableType = event.value.table_name;
		this.selectedTableTypeKey = event.value.spreadsheet_key;

		if(this.csvFileRef){
			// console.log(this.csvFileRef);
			// console.log(this.csvFileRef.nativeElement.value);
			this.csvFile = null;
			this.csvFileRef.nativeElement.value = null;
		}

		this.hasSelectedFile = false;
		this.validateCSV = false;
		this.validateCSVSize = false;
		this.localUrl = null;
		this.records = null;
		this.fields = null;

		console.log(this.csvFile);
		console.log(event);
		console.log(this.selectedTableType);
		console.log(this.selectedTableTypeKey);
		this.generateFields(event.value.table_name);
	}
	checkCSVEncodeing(event: any): Promise<any> {
		return new Promise((resolve) => {
			var file = event.target.files[0];

			var reader = new FileReader();
			// reader.onload = function(e) {
			reader.onload = (event: any) => {
				const Encoding = require('encoding-japanese');
				var codes = new Uint8Array(event.target.result);//new Uint8Array(event.target.result);
				var encoding = Encoding.detect(codes);
				console.log(encoding);
				this.encodingResult = encoding;

	      resolve();
				// document.getElementById('encoding').textContent = encoding;
				//
				// // Convert encoding to unicode
				// var unicodeString = Encoding.convert(codes, {
				//   to: 'unicode',
				//   from: encoding,
				//   type: 'string'
				// });
				// document.getElementById('result').value = unicodeString;
			};
			reader.readAsArrayBuffer(file);
    });
	}

  checkCSVArrayCompare(event: any): Promise<any> {
		this.isLoading = true;
		return new Promise((resolve) => {
      let input = event;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = (event: any) => {
        let csvData = reader.result;
        console.log('csvData', csvData);
        this.localUrl = csvData;
        console.log('localUrl', this.localUrl);
        console.log('event.target.result', event.target.result);
        resolve(csvData);
      };
      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };
      this.csvFile = input.files[0];
      console.log(this.csvFile);
    });
	}

	onSelectFile($event: any): void {
		// this.checkCSVEncodeing($event);
		this.checkCSVEncodeing($event).then(() => {
			console.log(this.encodingResult);

			// get character encoding
			// const encoding = require('encoding-japanese');
			// var content = new Uint8Array(csvData);
			// var detected = encoding.detect(content);
			// if (detected === 'UTF8') {
			// 	console.log('Encoding is UTF-8');
			// }
			// if (detected === 'JIS') {
			// 	console.log('Encoding is JIS');
			// }
			// if (detected === 'SJIS') {
			// 	console.log('Encoding is SJIS');
			// }

			let text = [];
			let files = $event.srcElement.files;

      console.log('SIZE: ',files[0].size);



      if (this.isValidCSVFile(files[0])) {
          this.checkCSVArrayCompare($event.target).then((csvData) => {
            // console.log('res', res);
            //use to split header data fields
            let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
            //header data fields
            let headersRow = this.getHeaderArray(csvRecordsArray);
            console.log('heasersRow:', headersRow)

            console.log('ARRAY COMPARE', this.compareArrays(this.fields, headersRow));
            //compare and validate if same field with database and csv
            this.validateCSV = this.compareArrays(this.fields, headersRow);
            //optional this line of code may output row lengts or output all the data on csv.
            this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
            console.log('records:', headersRow)
            this.hasSelectedFile = true;
    				this.isLoading = false;
            if(this.validateCSV){
              if (files[0].size <= 20000000) {
                this.validateCSVSize = true;
              }
              else{
                this.validateCSVSize = false;
              }
            }
      		});


			} else {
			  alert("Please import valid .csv file.");
			  // this.fileReset();
			}

      // console.log(files.size);
      //
      // return;



		});

		// console.log(this.localUrl);
		// console.log(this.csvFile);
		// console.log(this.csvFile.name);
		// console.log(this.selectedTableType);
  }
  submit() {
		this.isLoading = true;
		this.service.uploadCSV(this.csvFile, this.selectedTableType, this.selectedTableTypeKey).subscribe(res => {
      if(res.success){
				console.log('Swerswerte!');
				console.log(res);
				this.isLoading = false;
				this.showSuccessDialog();
      }
			this.isLoading = false;
    })
  }

	generateFields(table_value:any){
    this.service.getFields(table_value).subscribe(res => {
      if(res.success){
		var newfields; 
	     newfields = res.payload.fields;
        //this.fields = res.payload.fields;
		if(table_value == "store_master" || table_value == "carrier_master"){ 
			newfields.splice(newfields.indexOf("id"), 1);
			newfields.splice(newfields.indexOf("created_by"), 1);
			newfields.splice(newfields.indexOf("created_date"), 1);
			newfields.splice(newfields.indexOf("updated_by"), 1);
			newfields.splice(newfields.indexOf("updated_date"), 1);
			newfields.splice(newfields.indexOf("deleted_by"), 1);
			newfields.splice(newfields.indexOf("deleted_date"), 1);
		}
		else { 
			newfields.splice(newfields.indexOf("id"), 1);
			newfields.splice(newfields.indexOf("fid"), 1); 
			//this.fields.splice(this.fields.indexOf("id"), 1);
        	//this.fields.splice(this.fields.indexOf("fid"), 1);
			// this.fields.splice(this.fields.indexOf("wms_id"), 1);
		}
		this.fields = newfields
        console.log(this.fields);
      }
    })
  }

	uploadListener($event: any): void {

  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
	  console.log("HI PO", csvRecordsArray, headerLength)
    let csvArr = [];

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(',');
      if (curruntRecord.length == headerLength) {
        // let csvRecord: CSVRecord = new CSVRecord();
				let csvRecord: any;
        // csvRecord.id = curruntRecord[0].trim();
        // csvRecord.firstName = curruntRecord[1].trim();
        // csvRecord.lastName = curruntRecord[2].trim();
        // csvRecord.age = curruntRecord[3].trim();
        // csvRecord.position = curruntRecord[4].trim();
		// csvRecord.mobile = curruntRecord[5].trim();
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

	compareArrays(array1, array2) {
		if (array1.length === array2.length){
			console.log("dfeawrerere",array1, array2)
			return array1.every((a, index) => a === array2[index])
		}
	    else { 
			return false
		}
	        
	}

	showSuccessDialog(){
		const dialogRef = this.dialog.open(SuccessDialogComponent, {
			width: '500px',
			// data: {
			// 	name: val.cat_name
			// }
		})
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				console.log(result);
				// console.log(this.selectTableTypRef);
				// console.log(<HTMLInputElement>this.selectTableTypRef);
				// console.log(<HTMLInputElement>this.selectTableTypRef.value);
				// console.log(this.selectTableTypRef.nativeElement);
				// console.log(this.selectTableTypRef.nativeElement.value);
				// <HTMLInputElement>this.selectTableTypRef.value = null;

				if(this.csvFileRef){
					this.csvFile = null;
					this.csvFileRef.nativeElement.value = null;
				}
				this.hasSelectedFile = false;
				this.validateCSV = false;
				this.validateCSVSize = false;
				this.localUrl = null;
				this.records = null;
				// this.fields = null;
			}
		})
	}

}
