import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordService } from '../shared/reset-password.service';
import { ResetPasswordData } from '../shared/reset-password.prov';
import { MatDialog, MatSnackBar } from '@angular/material';

//import { PasswordHash } from '../../../../../node_modules/password-hash/lib/password-hash.js';

@Component({
  selector: 'app-set-newpassword',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class SetNewpasswordComponent implements OnInit {

  logo: string = "G-Retail";
  title: string = 'パスワードをリセットする';
  subtitle: string = '新しいパスワードを２回入力してください。';

  setPasswdForm: FormGroup;
  hide: boolean = true;
  public isPasswordReset = false;

  constructor(
    private resetPasswordData : ResetPasswordData,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar : MatSnackBar,
    private resetpasswordService: ResetPasswordService,
    
  ) { }

  ngOnInit() {
    if(!this.resetPasswordData.data) {
      this.router.navigate(['/']);
    } else {
      this.createForm();
    }
  }

  createForm() {
    this.setPasswdForm = this.formBuilder.group({
      mail_address : this.resetPasswordData.data.email,
      password_url : this.resetPasswordData.data.url,
      password: [ '',
      [
        Validators.required,
        Validators.maxLength(24),
        Validators.minLength(8),
        Validators.pattern('^[a-zA-Z0-9@#$%^&*()]+$')
        ]
      ],
      passwd_confirm: [ '', 
        [
          Validators.required,
          Validators.maxLength(24),
          Validators.minLength(8),
          Validators.pattern('^[a-zA-Z0-9@#$%^&*()]+$')
        ]
      ]
    });
  }

  //whether it has special character and string at least 1
  checkCharType(input, charType){
    switch(charType){
      //whether include alhabet or not
      case "alphabetic":
      return (input.match(/[a-zA-Z]+/)) ? true : false;

      case "special":
      return (input.match(/[@#\$\%\^&\*\(\)"\_""\-""\.""\?""\!""\+"]+/)) ? true : false;
    }
  }

  doCheck(input){
    if(this.checkCharType(input,'alphabetic') 
      == true && this.checkCharType(input,'special')) { 
      return 0;
    } else { 
      return 1;
    }
  }

  onSubmit() {  
    if(this.setPasswdForm.invalid){
      this.resetpasswordService.showAtleastMessage();
    } else { 
      //post data check
      var datas = this.setPasswdForm.getRawValue();

      if(datas.password !== datas.passwd_confirm){
        this.resetpasswordService.showDiffMessage();
        return;

      } else if(this.doCheck(datas.password) == 1 || this.doCheck(datas.passwd_confirm) == 1){
        this.resetpasswordService.showAtleastMessage();
        return;
      }

        const passwd = this.setPasswdForm.getRawValue();  
        //pass update
        this.resetpasswordService.updatePassword(passwd).subscribe((res: Response) => {
        let response : any = res;
        if(response.success) {
          this.router.navigate(['reset-password/complete']);
        } else if (response.error == 'Invalid password or password was already used before'){
          this.snackBar.open(`パスワードは許可されていません。
          もう一度やり直してください。`, null, {
            duration: 2000,
          });
          return false
        }
        console.log(response)
        //
        }, error => {
          console.log('failure:'+error);
          //this.resetpasswordService.showNotExisitingMessage(); have to create hereafter
      });
    }
  }
}
