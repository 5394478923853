import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { ChainOptComponent } from './../chain-opt/chain-opt.component';
import { ChainViewComponent } from './../chain-view/chain-view.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort  } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Chain_list } from '../shared/chain.model';
import { ChainService } from '../shared/chain.service';

@Component({
  selector: 'app-chain-list',  
  templateUrl: './chain-list.component.html',
  styleUrls: ['./chain-list.component.scss']
})
export class ChainListComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  users: Chain_list[] = [];
  dataSource: MatTableDataSource<Chain_list>;
  displayedColumns = ['id', 'name', 'name_brand', 'file_name', 'viewAction', 'editAction', 'deleteAction'];
  pbVisible: boolean = false;
  
  constructor(public service: ChainService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.users);
    this.getChains();
    this.dataSource.sort = this.sort;
  }

  getChains(){
    this.service.getUserList().subscribe(res => {
      this.pbVisible = true
      if(res.success){
        this.users = res.payload.chains
        if(this.users.length !== 0){
          this.dataSource.data = this.users
          this.dataSource.filterPredicate = (data: Chain_list, filters: string) =>{
            const matchFilter = [];
            const filterArray = filters.split(' ')
            const columns = [data.name, data.name_brand, data.file_name]

            filterArray.forEach(filter =>{
              const customFilter = [];
              columns.forEach(column => customFilter.push(column.toLowerCase().includes(filter)));
              matchFilter.push(customFilter.some(Boolean));
            })
            return matchFilter.every(Boolean);
          }
        }
      }
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDiaog(rowData: Chain_list){
    this.dialog.open(ChainViewComponent, { width: '980px',height:'550px', 
    data: rowData })
  }

  openOptionDialog(opt: string, val: Chain_list){
    const dialogRef = this.dialog.open(ChainOptComponent, { width: '980px',height:'550px',  
    data: {
      option: opt,
      id: val.id, 
      name: val.name, 
      name_brand: val.name_brand, 
      file_name: val.file_name,
      chain_no: val.chain_no,
      name_for_display: val.name_for_display
    },
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getChains()
    })
    
  }
  openOptionDialogAdd(opt: string){
    const dialogRef = this.dialog.open(ChainOptComponent, { width: '980px', height:'550px' , 
    data: {
        option: opt, id: '', coop_name: '', 
        coop_contactno: '', coop_address: ''
      },
      disableClose: true 
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res) this.getChains()
    })
  }

  onDelete(val: Chain_list){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        name: val.name
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
        let body = {
          'chain_no': val.chain_no,
          'id': val.id,
          'deleted_by': tmp.userid  
        }
        this.service.saveChain(body, 'delete')
        .subscribe(res => {
          this.getChains()
        })
      }
    })
  }

}
