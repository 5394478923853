import { Injectable } from '@angular/core';
import { inboundInquiry, DataService, allOrdersUrl , inboundMaintenanceUrl, inboundCollectionUrl ,maintenanceTaskrecordsUrl} from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class InboundService {

  constructor(private dataService: DataService) { }

  getList(i: number, body: any){
    if(i === 1)  // Inquiry
      return this.dataService.get(inboundInquiry, "chainId="+body.chainId+"&statHead="+body.statHead+"&statLevel="+body.statLevel);
    if(i === 2)  // Order
      return this.dataService.get(allOrdersUrl, "chainId="+body.chainId+"&statHead="+body.statHead+"&statLevel="+body.statLevel+"&cc_cancel="+body.cc_cancel+"&wh_cancel="+body.wh_cancel);
    if(i === 3)   // Maintenance
      return this.dataService.get(inboundMaintenanceUrl, "chainId="+body.chainId+"&statHead="+body.statHead+"&statLevel="+body.statLevel+"&escStat="+body.escStat);
    if(i === 4)  // Collection 
      return this.dataService.get(inboundCollectionUrl, "chainId="+body.chainId+"&statHead="+body.statHead+"&statLevel="+body.statLevel);
  }
  maintenanceGetimages(body){
    return this.dataService.post(maintenanceTaskrecordsUrl + 'get-images', body);
  }
}
