import { Component, Inject, OnInit } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';

import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageToolsSourceService, Image } from './shared/image-tools-source.service'

@Component({
  selector: 'app-image-tools',
  templateUrl: './image-tools.component.html',
  styleUrls: ['./image-tools.component.scss']
})
export class ImageToolsComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<ImageToolsComponent>,
    public dialog: MatDialog) { }

  openLink(event: MouseEvent, source: string): void {
    this.bottomSheetRef.dismiss(source);
    event.preventDefault();
  }

  onFileSelected(event) {
    // const file = event.target.files[0];
    this.bottomSheetRef.dismiss(event)
  }

  openDialog() {
    let dialogRef = this.dialog.open(ImageToolsComponentDialog, {
      width: '800px',
      autoFocus: false,
      data: this.data,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.bottomSheetRef.dismiss(result);
    });
  }
}

@Component({
  selector: 'image-tools-component-dialog',
  templateUrl: './image-tools-dialog.html',
  styleUrls: ['./image-tools.component.scss']
})
export class ImageToolsComponentDialog {
  dialogData: Image[] = [];

  selectedData: Image;
  selectedDatas: Image[] = [];

  sources = ['Branches', 'Coupons', 'Menu', 'News'];
  selectedSource: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: ImageToolsSourceService,
    public dialogRef: MatDialogRef<ImageToolsComponentDialog>) { 
  }

  ngOnInit() { }

  dataSelected(value) {
    if (this.data.multifile) {
      this.selectedDatas.push(value);
    } else {
      this.selectedData = value;
    }
  }

  onDialogClose() {
    if (this.data.multifile) {
      this.dialogRef.close(this.selectedDatas)
    } else {
      this.dialogRef.close(this.selectedData)
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelect(value) {
    this.dialogData = [];
    this.service.getImages(value.toLowerCase()).subscribe(response => {
      if (response.result_code == 0) {
        let r = response.ImageList;
        for (let i of r) {
          let image = new Image();
          image.image_url = i;
          this.dialogData.push(image);
        }
      } else if (response.result_code == 1) {

      }
    });
  }
}

