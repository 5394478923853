import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { COMPANY_NAME, COMPANY_NAME_SUB } from '../../shared/data.service';
import { AuthService } from '../shared/auth.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public companyName = COMPANY_NAME;
  public companyNameSub = COMPANY_NAME_SUB;
  
  title: string = 'ADMINISTRATOR';
  subtitle: string = 'Please sign in to get access.';
  userForm: FormGroup;

  returnUrl: string;

  public isSigningIn = false;

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    if (this.authService.isSignedIn || this.authService.hasCurrentUser()) {
      this.router.navigate(['/main'])
    }
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      username: [ '', Validators.required ],
      password: [ '', Validators.required ]
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.userForm.invalid) {
        return;
    }
    console.log('User Form Value :: ' + JSON.stringify(this.userForm.getRawValue()))
    this.isSigningIn = true;
    let userform: any = this.userForm.getRawValue();
    // this.authService.signin(this.userForm.getRawValue()).subscribe(response => {
    //   this.isSigningIn = false;
    //   if (response.result_code == 0) {
    //     this.authService.isSignedIn = true;
    //     this.authService.setUser(response);

    //     let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/main';

    //     // Redirect the user
    //     this.router.navigate([redirect]);
    //   } else if (response.result_code == 1) {
    //     this.authService.showOtherMessage('Invalid username or password.')
    //   }
    // });
    let body: any = {
      'username': userform.username,
      'password': userform.password
    }
    this.authService.signin2(userform.username, userform.password)
    .subscribe(res =>{
      this.isSigningIn = false;
      let result: any = res
      if(result.ok){
        
        this.authService.isSignedIn = true;
        this.authService.setUser(result.body);
        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/main/dashboard';
        this.router.navigate([redirect]);
      } else {
          this.snackBar.open('メールアドレスまたはパスワードが違います。', null, {
            duration: 3000,
          });
          return false
      }
    })
  }
}
