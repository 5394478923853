import { Component, Input, OnInit } from '@angular/core';
import { ImageToolsService, Image } from '../shared/image-tools.service';

@Component({
  selector: 'shared-image-tools-detail',
  templateUrl: './image-tools-detail.component.html',
  styleUrls: ['./image-tools-detail.component.scss']
})
export class ImageToolsDetailComponent implements OnInit {
  @Input() imageType: string;
 
  constructor(public imageTools: ImageToolsService) { }

  ngOnInit() { }

  onFileSelected(event) {
    this.imageTools.image = new Image();
    this.imageTools.image.image_name = event.target.files[0];
    this.imageTools.image.image_type = this.imageType;

    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageTools.image.image_url = e.target.result;
    }
    reader.readAsDataURL(this.imageTools.image.image_name);
  }

  clearFileInput() {
    this.imageTools.image = null;
  }

  openImageToolsComponent() {
    this.imageTools.openImageToolsComponent(false).subscribe(result => {
      
      if (result === undefined) return;
      if (result.target == undefined) {
        this.imageTools.image = result;
        return;
      }
      this.onFileSelected(result);
    });
  }
}
