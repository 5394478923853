import { Component, Input, OnInit } from '@angular/core';
import { ImageToolsService, Image } from '../shared/image-tools.service';

@Component({
  selector: 'shared-image-tools-detail-multiple',
  templateUrl: './image-tools-detail-multiple.component.html',
  styleUrls: ['./image-tools-detail-multiple.component.scss']
})
export class ImageToolsDetailMultipleComponent implements OnInit {
  @Input() imageType: string;

  constructor(public imageTools: ImageToolsService) { }

  ngOnInit() { 
    
  }
  
  onFileSelected(event) {
    for (let img of event.target.files) {
      let image = new Image();
      image.image_name = img;
      image.image_type = this.imageType;

      let reader = new FileReader();
      reader.onload = (e: any) => {
        image.image_url = e.target.result;
        this.imageTools.images.push(image);
      }
      reader.readAsDataURL(img);
    }
  }

  clearFileInput(image: Image) {
    this.imageTools.images.splice(this.imageTools.images.indexOf(image), 1);
  }

  openImageToolsComponent() {
    this.imageTools.openImageToolsComponent(true).subscribe(result => {
      if (result === undefined) return;
      if (result.target == undefined) {
        for (let r of result) {
          this.imageTools.images.push(r);
        }
        return;
      }
      this.onFileSelected(result);
    });
  }
}
