import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserList } from '../shared/user-list-interface';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {

  localUrl = "/assets/images/user.png";

  userType: string[] = ['コールセンター', 'ラウンダー', '博報堂', 'Google', '倉庫', 'ベンダー', 'ショップ']
  role: string[] = ['管理者', '一般']

  constructor(public dialogRef: MatDialogRef<UserViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserList) { }

  ngOnInit() {

  }

}
