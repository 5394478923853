import { Injectable } from '@angular/core';

@Injectable()

export class EditOrder{
    edit_order: any = null
    bulk_order: any = null
    csv_order: string = null
    csv_size: number = 0
    public constructor() { }
}