import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-second-cat-management',
  templateUrl: './store-second-cat-management.component.html',
  styleUrls: ['./store-second-cat-management.component.scss']
})
export class StoreSecondCatManagementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
