import { Component, OnInit } from '@angular/core';
import { CollectionService } from './shared/collection.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
  providers: [ CollectionService ]
})
export class CollectionComponent implements OnInit {

  constructor(public service: CollectionService) { }

  ngOnInit() {
  }
}
