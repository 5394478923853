import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { CarrierFirstCatUrl, } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierFirstCatService {

  constructor(private dataService: DataService) { }

  getFirstCategList(){
    return this.dataService.get(CarrierFirstCatUrl, null);
  }
  saveCateg(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.carrierFirstCatOpt(CarrierFirstCatUrl + '/delete', body, opt)
    else
      return this.dataService.carrierFirstCatOpt(CarrierFirstCatUrl, body, opt)
  }
}
