import { InquiryService } from './../../inquiry/shared/inquiry.service';
import { Component, OnInit, ViewChild, HostListener, NgModule, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute,Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Collection } from '../shared/maintenance.model';
import { MaintenanceService } from '../shared/maintenance.service';
import { MatDialog } from '@angular/material';
import { MaintenanceData } from '../shared/maintenance.prov';
import { RelationalRefData } from 'src/app/order/shared/order.prov';


export interface images {
  url: any;
  mess: any; 
}

@Component({
  selector: 'app-maintenance-complete',
  templateUrl: './maintenance-complete.component.html',
  styleUrls: ['./maintenance-complete.component.scss'],
  providers: [ StoreService, MaintenanceService, InquiryService ]
})
export class MaintenanceCompleteComponent implements OnInit, OnDestroy {

  public id: string;
  store: Store;
  inquiry: Collection;
  isLoading = false;
  hasData: boolean = false;
  message = '';
  refno = '';
  type = '';
  pMsg = '';
  uFlag = false;
  isClearRefs = true;
  item_selected = '';

  urls : images[] = [];
  tempData : any
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private maintenanceService: MaintenanceService,
    private maintenanceData: MaintenanceData,
    public http: HttpClient,
    public dialog: MatDialog,
    private inquiryService: InquiryService,
    private refData:RelationalRefData,
  ){}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getStore(); 
    this.tempData = this.maintenanceData;
    this.message = this.maintenanceData.data.message;
    this.refno = this.maintenanceData.data.refno;
    this.item_selected = this.maintenanceData.data.item_selected
    
    switch(this.tempData.data.type){
      case '1' : {
        this.type = '修理';
        break;
      }
      case '2' : {
        this.type = '交換';
        break;
      }
      case '3' : {
        this.type = '調査';
        break;
      }
      case '4' : {
        this.type = 'その他';
        break;
      }
    }
    for(let file of this.tempData.mainFile){
      var reader = new FileReader();
      reader.onload = (e: any) => {
        this.urls.push({
          url : e.target.result,
          mess : file.mess
        })
      }
      reader.readAsDataURL(file.mfile);
      console.log('MaintenanceCompleteComponent', this.refData)
    }
    
    /*if(this.collectionData.collection){
      if(this.collectionData.collection.length>0){
        this.pMsg = this.collectionData.inqucollectionry.detail;
      }
    }*/
    this.uFlag = false;

    this.maintenanceData.mainFile = [];
    this.maintenanceData.data = undefined;

  }

  getStore() {
    this.storeService.getStore(this.id).subscribe(res => {
      this.store = res.payload.store[0];
      console.log(this.store);
    });
  }

  chainName: string
  getChainName(){
    this.inquiryService.getChainName(this.store.chain_no)
    .subscribe(res => {
      this.chainName = res.payload.chain[0].name
    })
  }

  ngOnDestroy() {
    if(this.isClearRefs){
      this.refData.inq_ref = ''
      this.refData.order_ref = ''
      this.refData.coll_ref = ''
      this.refData.mainte_ref = ''
      this.refData.updID = null
    }
  }

  toCollection(){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/collection/input/'+this.id]);
  }

  toOrder(){
    this.isClearRefs = false
    this.router.navigate(['../../../../main/order/input/'+this.id]);
  }

}
