import { Injectable } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { categoriesUrl, addCategoryUrl, delCategoryUrl } from './../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class ItemCategService {

  constructor(private dataService: DataService) { }

  getCategList(){
    return this.dataService.get(categoriesUrl, null);
  }
  saveCateg(body: any, opt :string){
    if(opt === 'delete')
      return this.dataService.categoryOpt(delCategoryUrl, body, opt)
    else
      return this.dataService.categoryOpt(addCategoryUrl, body, opt)
  }
}
