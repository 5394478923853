import { Injectable } from '@angular/core';
import { DataService, fileUploadUrl } from '../../shared/data.service';

@Injectable({
  providedIn: 'root'
})
export class ManualService {

  constructor(private dataService: DataService) { }

  getManualList(){
    return this.dataService.get(fileUploadUrl, '/3');
  }
  
  fileUpload(body: any, files: any){

    return this.dataService.fileUpload(fileUploadUrl, body, files)
  }
}