import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-blank-list',
  templateUrl: './blank-list.component.html',
  styleUrls: ['./blank-list.component.scss']
})
export class BlankListComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}
