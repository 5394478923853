import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SidenavService } from '../sidenav.service';

@Component({
  selector: 'shared-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input() title: string;
  @Input() service: any;
  @Input() hasActionButton: boolean = true;

  constructor(
    private location: Location, 
    private route: ActivatedRoute,
    private router: Router,
    private sidenavService: SidenavService,) { }

  ngOnInit() {
  }

  toggleSidenav() {
    this.sidenavService.toggleSideNav();
  }

  back() {
    console.log(this.route.parent.firstChild.snapshot.url.toString())
    // this.location.back();
    this.router.navigate(['./' + this.route.parent.firstChild.snapshot.url.toString()], { relativeTo: this.route.parent })
    this.service.isDetailComponentShowing = false;
  }

  get isDetailComponentShowing() {
    return this.service.isDetailComponentShowing;
  }

  get isSettingsComponentShowing() {
    return this.service.isSettingsComponentShowing;
  }

  get isMenuVisible() {
    return this.sidenavService.menuVisibility;
  }

}
