import { ChainService } from './../shared/chain.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-chain-opt',
  templateUrl: './chain-opt.component.html',
  styleUrls: ['./chain-opt.component.scss']
})
export class ChainOptComponent implements OnInit {

  chainForm: FormGroup;
  opt: string
  disabledSubmit: boolean = false;
  isLoad = false
  showLoading = false
  showErrorText = false
  errorText = ''

  constructor(public dialogRef: MatDialogRef<ChainOptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder, private authService: ChainService) { }

  ngOnInit() {
    if(this.data.option === 'edit') this.opt = '変更'
    else this.opt = '法人新規追加'
    this.createForm();
  }

  createForm(): void {
    this.chainForm = this.formBuilder.group({
      name: [ this.data.name, Validators.required ],
      brand_name: [ this.data.name_brand, Validators.required ],
      file_name: [ this.data.file_name, Validators.required ],
      name_for_display: [ this.data.name_for_display, Validators.required ]
    });
  }

  onSubmit(){
    this.isLoad = true
    this.showLoading = true
    if (this.chainForm.invalid) {
      this.showLoading = false
      this.showErrorText = true
      this.errorText = '重要なフィールドを記入してください'
      return
    }
    // this.disabledSubmit = true;
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    let val: any = this.chainForm.getRawValue()

    let body: any;
    if(this.data.option === 'add')
      body = { 'name': val.name,
        'brand_name': val.brand_name,
        'file_name': val.file_name,
        'user_id': tmp.userid,
        'name_for_display': val.name_for_display }
    else if(this.data.option === 'edit')
      body = { 
        'id': this.data.id,
        'name': val.name,
        'brand_name': val.brand_name,
        'file_name': val.file_name,
        'updated_by': tmp.userid,
        'chain_no': this.data.chain_no,
        'name_for_display': val.name_for_display }

    this.authService.saveChain(body, this.data.option)
    .subscribe(res => {
      if(res){
        this.dialogRef.close(true)
      }else{
        this.showLoading = false
        this.showErrorText = true
        this.errorText = '接続の問題が再試行してください'
      }
    })
  }

  cancelSave(){
    this.isLoad = false
    this.showLoading = false
    this.showErrorText = false
  }

}
