import { InquiryService } from './../../inquiry/shared/inquiry.service';
import { Component, OnInit,ViewChild,HostListener, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '../../store/shared/store.model';
import { StoreService } from '../../store/shared/store.service';
import { Collection } from '../shared/collection.model';
import { CollectionService } from '../shared/collection.service';
import { CollectionData } from '../shared/collection.prov';
import { RelationalRefData } from 'src/app/order/shared/order.prov';
import { BadgeSocketService } from 'src/app/shared/badge-socket.service';

@Component({
  selector: 'app-collection-confirm',
  templateUrl: './collection-confirm.component.html',
  styleUrls: ['./collection-confirm.component.scss'],
  providers: [  CollectionService ] 
})
export class CollectionConfirmComponent implements OnInit {
  
  public id: string;
  store: Store;
  isLoading = false;
  hasData: boolean = false;
  message = '';
  pMsg = '';
  uFlag = false;
  collection: Collection;
  remarks : any;
  address : any;
  shop_incharge : any;
  shop_date_hopes : any;
  urg_flg : any;
  phone_number: any;
  btnDisable = false

  constructor(
    public badgeService: BadgeSocketService,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private collectionService: CollectionService,
    private collectionData: CollectionData,
    public http: HttpClient,
    private relData: RelationalRefData,
  ){}

  ngOnInit() {
    
    this.remarks = this.collectionData.remarks;
    this.address = this.collectionData.address;
    this.shop_incharge = this.collectionData.shop_incharge;
    this.shop_date_hopes = this.collectionData.shop_date_hopes;
    this.urg_flg = this.collectionData.urg_flg;
    this.phone_number = this.collectionData.phone_number;

    this.id = this.route.snapshot.paramMap.get('id');
    this.store = this.collectionData.store;
    //this.uFlag = false;  
    console.log('collectionData', this.collectionData)
    console.log(this.collectionData.store)
  }
 
  queryCollection:any;
  toComplete(){
    this.btnDisable = true
    let tmp: any = JSON.parse(sessionStorage.getItem('currentUser'))
    this.queryCollection = {
      'store_id': this.id,
      'remarks': this.collectionData.remarks,
      'address': this.collectionData.address,
      'shop_incharge': this.collectionData.shop_incharge,
      'shop_date_hopes': this.collectionData.shop_date_hopes,
      'phone_number': this.collectionData.phone_number,
      'urg_flg': this.collectionData.urg_flg === true ? 1 : 0,
      'user_id': tmp.userid,
      'provider_name': "",
      'driver_name': "",
      'driver_contact_number': "0",
      'coll_date': '1970-01-01', 
      'coll_time': "0",
      'status': "0",
      
    }
    
    this.collectionService.setCollection(this.queryCollection)
    .subscribe(
      res => {
        this.btnDisable = false
        this.notif()
        var result = JSON.parse(JSON.stringify(res));
        this.collectionData.ref_code = result.payload.collectionRefNo;
        this.relData.coll_ref = result.payload.collectionRefNo;
        
        if(this.relData.inq_ref.length == 0){
          this.router.navigate(['main/collection/complete/' + this.id]);
          return
        }
        
        if(this.relData.inq_ref.length !== 0 || this.relData.mainte_ref.length !== 0 || this.relData.coll_ref.length !== 0){
        
          let tmpuser: any = JSON.parse(sessionStorage.getItem('currentUser'))
          let body = {
            'inquiry': this.relData.inq_ref,
            'order': this.relData.order_ref,
            'collection': this.relData.coll_ref,
            'maintenance': this.relData.mainte_ref,
            'created_by': tmpuser.userid,
            'id': this.relData.updID
          }
          
          if(this.relData.updID == null){
            this.collectionService.saveRelation(body)
            .subscribe(res => {
              let tmprefid: any = res
              this.relData.updID = tmprefid.body.insertId
              this.notif()
              this.router.navigate(['main/collection/complete/' + this.id]);
            })
          }else{
            console.log('UPDATING RELATION', body)
            this.collectionService.updateRelation(body)
            .subscribe(res => {
              this.notif()
              this.router.navigate(['main/collection/complete/' + this.id]);
            })
          }
        }else{
          this.notif()
          this.router.navigate(['main/collection/complete/' + this.id]);
        }
      }
    );
  }

  notif(){
    let view_tmp = {
      'cc': false,
      'hd': false,
      'wh': true,
      'rr': false,
      'gg': false,
      'vr': false
    }
    //this.badgeService.pushBadgeNotif(view_tmp)
  }

  onBack(){
    this.collectionData.store = this.store
    this.collectionData.store_id = this.id
    this.collectionData.remarks = this.remarks 
    this.collectionData.address = this.address
    this.collectionData.shop_incharge = this.shop_incharge 
    this.collectionData.shop_date_hopes = this.shop_date_hopes
    this.collectionData.urg_flg = this.urg_flg
    this.collectionData.phone_number = this.phone_number
    this.router.navigate(['/main/collection/input/' + this.id]);
  }

}
