export class Carousel {

    private position: number = 1
    private limit: number = 0

    constructor(){}

    getPosition(){return this.position}

    setLimit(n: number){this.limit = n}

    setPosition(n: number){
        this.position+=n
        return this.position = this.checkNum(this.position)
    }

    defPosition(n: number){
        this.position=n
        return this.position = this.checkNum(this.position)
    }

    checkNum(n: number){
        if(n < 1) return this.limit
        if(n > this.limit) return 1
        return n
    }

}