import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'shared-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() mode: string = 'indeterminate';
  @Input() progress: any;

  constructor() { }

  ngOnInit() {
  	
  }

}
