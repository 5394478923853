import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Item,Categories } from '../../shared/order.model';
import { OrderService } from '../../shared/order.service';
import { Carousel } from 'src/app/shared/carousel.config';
import { ItemService } from '../../../item-management/shared/item.service';

export interface KeyValue {
  key: any;
  value: string;
}

@Component({
  selector: 'app-item-detail-dialog',
  templateUrl: './item-detail-dialog.component.html',
  styleUrls: ['./item-detail-dialog.component.scss'],
  providers: [ OrderService ]
})
export class ItemDetailDialogComponent implements OnInit {

  item: Item[] = [];
  categories: Categories[] = [];
  tier = ''

  tierLvl: KeyValue[] = [
    { key: 100, value: "1" },
    { key: 101, value: "1+" },
    { key: 200, value: "2" },
    { key: 300, value: "3" },
    { key: 400, value: "その他" }
  ]

  slide: Carousel
  idFiles: any[] = []

  constructor(
    private matDialogRef: MatDialogRef<ItemDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService,public itemService: ItemService
  ) { }

  ngOnInit() {
    console.log(this.data);
    for(let x = 0; x < this.tierLvl.length; x++){
      if(this.data.tier === this.tierLvl[x].key){
        this.tier = this.tierLvl[x].value
      }
    }
    
    if (this.data.sub_imgs.toString() == '()'){
      if (this.data.img != "") { 
      this.idFiles.push({"id":0,"filename": this.data.img })
      } else {  
        this.idFiles.push({"id":0,"filename": "./assets/img/iconfinder_imgblank.png" })
      } 
    } else {
      this.getSubImgUrls(this.data.sub_imgs)
    }
    this.slide = new Carousel
  }

  getSuffix(key:number){
    key.toString();
    return this.orderService.sufList[key].value;
  }

  getMnfc(key:number){
    key.toString();
    return this.orderService.mnList[key].value;
  }

  getSubImgUrls(sub_img){
    
    this.itemService.getSubImgUrls(sub_img)
    .subscribe(res => {
      console.log(res.payload.upload_files )
      
        if (this.data.img != "") { 
        this.idFiles.push({"id":0,"filename": this.data.img })
        } else {  
          this.idFiles.push({"id":0,"filename": "./assets/img/iconfinder_imgblank.png" })
        } 
     
        if (res.payload.upload_files.length != 0  )
        {
          for(let x = 0; x < res.payload.upload_files.length; x++){
            this.idFiles.push(res.payload.upload_files[x]) 
          } 
        } 

        this.slide.setLimit(res.payload.upload_files.length + 1)
    })
    
  }
}

